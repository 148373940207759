import React from "react";
import { Routes, Route } from "react-router-dom";

import UsersPanel from "../components/users/UsersPanel";
import DocumentTypesPanel from "../components/documenttypes/DocumentTypesPanel";
import RolesPanel from "../components/roles/RolesPanel";
import FunctionsPanel from "../components/functions/FunctionsPanel";
import AdminCompaniesPanel from "../components/companies/AdminCompaniesPanel";
import FeaturesPanel from "../components/features/FeaturesPanel";
import EstimateForm from "../components/estimates/EstimateForm";
import Home from "../components/home/HomePanel";
import biaI18n from "../commons/i18n/biaI18n";

import Menu from "./Menu";
import BiaComponent from "../commons/components/BiaComponent";
import ProtectedRoute from './ProtectedRoute';



class Header extends BiaComponent {
  disconnect(event) {
    
    let COOKIE_DOMAIN = ".bia360cae.com";

    if (process.env.NODE_ENV === "development") {
      COOKIE_DOMAIN = "localhost";
    } 

    event.preventDefault();
    //remove user cookie
    document.cookie = 'user=; domain=' + COOKIE_DOMAIN + '; Path=/; Max-Age=0;';
    //redirect to login page
    window.location.href = '/login.html';
  }

  render() {
    return (
      <div className="header">
        <div className="admin-logout" style={{ display: "inline-block" }}>
          <div>
            <a
              href="#/"
              style={{ color: "white" }}
              onClick={this.disconnect.bind(this)}
            >
              <i className="fa fa-sign-out"></i> {biaI18n.auth["logout"]} de {global.authenticateUserLogin}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

const MENU_EXPANDED_WIDTH = "16rem";
const MENU_COLLAPSED_WIDTH = "4rem";

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: false,
      mode: false,
      selected: null,
      profile: null,
      companies: [],
    };
  }

  componentDidMount() {
  }

  toggle(event) {
    event.preventDefault();
    this.setState({ collapsed: !this.state.collapsed });
  }

  render() {
    return (
      <div className="app">
        <Menu
          collapsed={this.state.collapsed}
          expandedWidth={MENU_EXPANDED_WIDTH}
          collapsedWidth={MENU_COLLAPSED_WIDTH}
          onToggle={this.toggle.bind(this)}
        />

        <div className="work-global">
          <Header
            companies={this.state.companies}
            selected={this.state.selected}
          />

          <div className="work">
            {/* <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/admin/users" element={<UsersPanel />} />
              <Route path="/admin/roles" element={<RolesPanel />} />
              <Route path="/admin/functions" element={<FunctionsPanel />} />
              <Route path="/admin/companies" element={<AdminCompaniesPanel />} />
              <Route path="/admin/documenttypes" element={<DocumentTypesPanel />} />
              <Route path="/admin/features" element={<FeaturesPanel />} />
              <Route path="/admin/estimate" element={<EstimateForm />} />
              <Route path="/admin/users" element={<UsersPanel />} />
            </Routes> */}
            <Routes>
              <Route path="/" element={<ProtectedRoute element={<Home />} />} />
              <Route path="/admin/users" element={<ProtectedRoute element={<UsersPanel />} />} />
              <Route path="/admin/roles" element={<ProtectedRoute element={<RolesPanel />} />} />
              <Route path="/admin/functions" element={<ProtectedRoute element={<FunctionsPanel />} />} />
              <Route path="/admin/companies" element={<ProtectedRoute element={<AdminCompaniesPanel />} />} />
              <Route path="/admin/documenttypes" element={<ProtectedRoute element={<DocumentTypesPanel />} />} />
              <Route path="/admin/features" element={<ProtectedRoute element={<FeaturesPanel />} />} />
              <Route path="/admin/estimate" element={<ProtectedRoute element={<EstimateForm />} />} />
            </Routes>
          </div>
        </div>
      </div>
    );
  }
}
