import React from "react";
import BiaComponent from "../commons/components/BiaComponent";
import { Link } from "react-router-dom";

export default class Menu extends BiaComponent {
  render() {
    let visibilityStyle = {
      display: this.props.collapsed ? "none" : "inline",
    };

    let visibilityStyleSublink = {
      display: this.props.collapsed ? "none" : "block",
    };

    let positionStyle = {
      width: this.props.collapsed
        ? this.props.collapsedWidth
        : this.props.expandedWidth,
    };

    let toggleText = this.props.collapsed ? ">>" : "<<";

    let admin = (
      <div>
        <Link className="link" to="#">
          <img alt="Admin item logo" src={require("../commons/img/5.png")} />
          <span style={visibilityStyle}>{this.i18n.menu["Admin"]}</span>
        </Link>
        <div className="sublink" style={visibilityStyleSublink}>
          <Link className="link" to="/admin/companies">
            <span>{this.i18n.menu["Companies"]}</span>
          </Link>
        </div>
        <div className="sublink" style={visibilityStyleSublink}>
          <Link className="link" to="/admin/users">
            <span>{this.i18n.menu["Users"]}</span>
          </Link>
        </div>
        <div className="sublink" style={visibilityStyleSublink}>
          <Link className="link" to="/admin/documenttypes">
            <span>{this.i18n.menu["DocumentTypes"]}</span>
          </Link>
        </div>
        <div className="sublink" style={visibilityStyleSublink}>
          <Link className="link" to="/admin/roles">
            <span>{this.i18n.menu.admin["Roles"]}</span>
          </Link>
        </div>
        <div className="sublink" style={visibilityStyleSublink}>
          <Link className="link" to="/admin/functions">
            <span>{this.i18n.menu.admin["Functions"]}</span>
          </Link>
        </div>
        <div className="sublink" style={visibilityStyleSublink}>
          <Link className="link" to="/admin/features">
            <span>{this.i18n.menu.admin["Features"]}</span>
          </Link>
        </div>
        <div className="sublink" style={visibilityStyleSublink}>
          <Link className="link" to="/admin/estimate">
            <span>{this.i18n.menu.admin["Estimate"]}</span>
          </Link>
        </div>
      </div>
    );

    return (
      <div className="menu" style={positionStyle}>
        <div className="logo">
          <img
            alt="Bia360 logo"
            src="/assets/img/load-logo.png"
            style={{ width: "90%", maxWidth: "11rem", verticalAlign: "middle" }}
          />
        </div>
        <div className="list">{admin}</div>
        <div className="toggle">
          <a href="#/" onClick={this.props.onToggle}>
            {toggleText}
          </a>
        </div>
      </div>
    );
  }
}
