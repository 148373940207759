import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "./commons/css/_fonts.scss";
import "./commons/css/font-awesome.min.css";
import "./commons/css/main.scss";

import App from "./components/App";
import "./repositories/AuthRepository";
import AuthRepository from "./repositories/AuthRepository";

global.language = "es";

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

let cookie = getCookie("user");

if (cookie === "") {
  let queryString = "";
  if (window.location.hash) {
    queryString = "?hash=" + window.location.hash;
  }
  window.location.replace(`/login.html${queryString}`);
} else {
  new AuthRepository().getAuthUser().then(result => {
    global.authenticateUserId = result.records[0].user.id;
    global.authenticateUserLogin = result.records[0].user.login;
    global.authenticateUserMail = result.records[0].user.mail;
    global.authenticatedUserIsAdmin = result.records[0].user.admin;
    
    if (!global.authenticatedUserIsAdmin){
      // Delete user cookie
      let COOKIE_DOMAIN = ".bia360cae.com";
      if (process.env.NODE_ENV === "development") {
        COOKIE_DOMAIN = "localhost";
      } 
      document.cookie = 'user=; domain=' + COOKIE_DOMAIN + '; Path=/; Max-Age=0;';
      
      //redirect to login page
      window.location.replace(`/login.html?error=true`);
    } else {
      ReactDOM.render(<Router><App /></Router>, document.getElementById("root"));
    }

  });
  
}
