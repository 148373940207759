export default {
  automatic: {
    Title: "Automatic processing of documentary requirements",
    Found: "The following workers were found in the document:",
    Processed:
      "The documentary requirement of the following workers has been met:",
    NotProcessed:
      "The following workers are not registered or do not have this documentary requirement:",
    Add: {
      Title: "Automatic insertion of workers ",
      Processed: "The following workers have been inserted:",
      NotProcessed:
        "The following workers already existed or errors were found when processing them:",
    },
    failedAutomaticDocWarningStart:
      "The document could not be validated automatically and",
    failedAutomaticDocWarningReview: "has been left under review",
    failedAutomaticDocWarningEnd:
      "Since it cannot be validated automatically, it only applies to this specific documentary requirement. Only documents downloaded directly from the original source and without any manipulation can be processed automatically.",
    failedAutomaticDocWarningTitle:
      "Unable to validate the document automatically",
  },
  assigned: {
    Workers: "Assigned workers ",
    Machines: "Assigned work teams",
    Companies: "Assigned contracts",
  },
  access: {
    Access: "Access control of workers and work teams",
    AccessTitle: "Access control",
    AccessWorkCenter: "Workplace",
    TotalWorkers: "uploaded workers ",
    CompaniesSearch: "Company",
    EntitiesSearch: "Worker / Work Team",
    SwitchSearchLabel: "Search by",
    authorizedByHand: "Authorized manually",
    worker: "Worker",
    Success: "Worker authorized correctly",
    Error: "Error authorizing the worker",
    SelectWorkerFirst: "Select a worker first",
    ObligatoryInfo: "All fields are required",
  },
  unassigned: {
    Workers: "Unassigned workers ",
    Machines: "Unassigned work teams",
  },
  exceptions: {
    Title: "Error",
    "501": "The document provided is not in ITA format",
    "503": "The worker could not be found in the document provided",
    "506": "No record date found in the document",
    "507":
      "The CIF of the document does not correspond to the company that is requesting it",
    "508": "The document has expired",
    "509":
      "The document provided is not a current Social Security payment certificate",
    "510": "The document is expired according to the type of expiration",
    "511": "The dates entered are outside the valid range",
    "512":
      "The document provided is not a current payment certificate of your tax obligations",
    "513": "The document provided is not in TC2 format",
    "514": "The date range must be later than or equal to the current date",
  },
  users: {
    Users: "Users",
    Saved: "User added correctly",
    ObligatoryInfo: "Enter a valid email and password",
    Add: "Add",
    AddIndustrialPropertyUser: "Add industrial property user",
    AddContractUser: "Add contract user",
    Edit: "Edit user",
    Deleted: "User deleted correctly",
    SureDelete: "Are you sure you want to to delete this user?",
    Delete: "Delete user",
    InvalidMail: "Invalid email",
    UserCreationError:
      "The user could not be created. Check if it already existed",
    IndustrialPropertyUsers: "Industrial property users",
    ContractorUsers: "Users of the contracts",
    LatestCredentialsSent: "Submitted on ",
    CredentialsSentSuccessfully: "Credentials sent successfully",
  },
  machines: {
    Saved: "Work team created correctly",
    ObligatoryInfo: "Enter a name and reference",
    Machines: "Work teams",
    Assign: "Assign work team",
    Save: "Work team assigned correctly",
    Select: "Select a work team",
    Add: "Add work team",
    Delete: "Delete work team",
    SureDelete: "Are you sure you want to delete this work team?",
    Deleted: "Work team deleted correctly",
    Edit: "Edit work team",
    NoAvailable: "No work team available",
  },
  score: {
    WorkCenters: "workplaces",
    Contractors: "contracts",
    Documents: "documents",
    Workers: "workers",
    DisabledWorkers: "inactives",
    DescriptionType: "Requirements",
    WorkersType: "worker",
    CompaniesType: "company",
    MachinesType: "team",
    Company: "company",
    Machines: "work teams",
    Roles: "roles",
    Contracts: "contracts",
  },
  menu: {
    Dashboard: "Dashboard",
    Statistics: "Statistics",
    Contracts: "Contracts",
    Documentation: "Documentation",
    Review: "Review and validation",
    Templates: "Templates",
    DocumentsToRead: "Information to read",
    Configuration: "Configuration",
    Workers: "Workers",
    Users: "Users",
    DocumentTypes: "Documentary requirements",
    WorkCenters: "Workplaces",
    Roles: "Worker roles",
    Functions: "Team roles",
    Contractors: "Contract agreements",
    Companies: "Companies",
    ClientCompanies: "Client companies",
    Machines: "Work teams",
    Admin: "Administration",
    DocumentsToCompanies: "Information to contractors",
    AccessesTitle: "Access",
    Access: "Access control",
    Accesses: "Access registry",
    InPlant: "Plant resources",
    TemporaryAuthorizations: "Temporary authorizations",
    NeedHelp: "Do you need help?",
    admin: {
      Roles: "Worker roles",
      Functions: "Team roles",
      Features: "New features",
      Estimate: "Quote submission",
    },
  },
  dashboard: {
    workers: {
      Workers: "workers",
      InvalidWorkersNotFound: "No workers found with invalid documentation",
      WorkersInvalidDocuments: "Invalid worker documents",
      WorkerDocuments: "Worker documents",
    },
    machines: {
      machines: "work teams",
      InvalidMachinesNotFound: "No work teams found with invalid documentation",
      MachinesInvalidDocuments: "Invalid  work team documents",
      MachinesDocuments: "Work team documents",
    },
    company: {
      InvalidCompaniesNotFound: "No companies found with invalid documentation",
      CompanyInvalidDocuments: "Invalid business documents",
      CompaniesDocuments: "Business documents",
    },
  },
  documents: {
    Uploaded: "Added on",
    UploadedBy: "Added by",
    InvalidatedBy: "Invalidated by:",
    Reason: "Reason:",
    ExpiredOn: "Expired the",
    ExpiresOn: "It will expire the ",
    TolerateTo: "tolerance until",
    Upload: "Add",
    UploadModel: "Model",
    UploadDocumentTitle: "Meet documentary requirement",
    Approved: "Document approved ",
    Denied: "Document denied",
    DocumentDeniedReason: "Reason for the denial",
    Roles: "Roles",
    ProvideReason: "Specify a reason for the denial",
    Saved: "Document added correctly",
    ErrorSaved: "Error adding the document",
    FileNotFound: "You must enter a valid file",
    ExtensionNotFound: "You must enter a file with a valid extension",
    InForceNotFound: "You must enter the effective date",
    EndDateNotFound: "You must enter the expiration date",
    InvalidCompanyDocuments: "Invalid business documents",
    Versions: "Versions",
    Files: "Files",
    RequiredFields: "The name and file are required",
    Critical: "CRITICAL",
    Dates: "Period of validity",
    RequirementLabel: "Requirement",
    CriticalDoc: "Critical document",
    Review: "Go to the review",
    Dashboard: "Go to the dashboard",
    MaxSizeDocument: "The size of the document is higher than allowed",
    StartDate: "Start date",
    EndDate: "End date",
    Origin: "Origin",
    OriginCOMPANY: "General template",
    OriginCONTRACTOR: "Company template",
    OriginWORK_CENTER: "Work center template",
    OriginROLE: "Worker role",
    OriginFUNCTION: "Machine role",
    OriginCONTRACT: "Contract template",
    ForceOverride: "Force override",
    Watch: "Watch",
    viewer: {
      reduce: "reduce",
      increase: "increase",
      reset: "reset zoom",
      rotateLeft: "rotate left",
      rotateRight: "rotate right",
      adjust: "adjust",
      download: "download",
      ItsNotPossiblePreviewThisFile: "It is not possible to preview this file.",
      YouMustDownloadTheFileToOpenIt: "You must download the file to open it.",
    },
  },
  chat: {
    Chat: "Chat",
    Comments: "Comments",
    NewComment: "Write a new comment",
    Send: "Send",
  },
  companies: {
    Created: "Company created correctly ",
    Updated: "Company updated ",
    ObligatoryInfo: "The name and CIF are mandatory",
    Companies: "Companies",
    ClientCompanies: "Client companies",
    Add: "Add company",
    Edit: "Edit company",
    Delete: "Delete company",
    DeletedError:
      "The company could not be deleted. There is related information",
    SureDelete: "Are you sure you want to delete this company?",
    NoAvailable: "There are no companies available",
    Assign: "Assign company",
    Saved: "Company assigned correctly",
    Select: "Select a company",
    Deleted: "Company deleted correctly ",
    NoAssigned: "No company assigned",
    Assigned: "Assigned companies:",
    Token: "Submission of documentary requirements",
    InvalidMailAddresses: "Invalid mail addresses",
    InvalidMailAddressesText:
      "We were unable to send the documentary requirements to the following addresses:",
    SureToken:
      "Are you sure you want to send all the documentary requirements to the client company?",
    TokenSent: "Requirements query link sent correctly",
    Recipients: "Recipients",
    InvalidMailAddress: "We were unable to send the documentary requirements",
    RelatedCompanies: "Related companies",
  },
  contractor: {
    Assign: "Assign contract",
    Contractors: "Contracts",
    Saved: "Agreement added to the contract correctly",
    SelectCompany: "Select a company",
    Delete: "Delete contract",
    Deleted: "Contract deleted correctly",
    noContractorsDefined:
      "No contractors have been defined for the current contract in this work center",
    ShowDeactivatedCompanies: "Show deactivated companies",
    MoreOptions: "More options",
    LockRoles: "Lock roles",
    Yes: "Yes",
    No: "No",
    DisableContract: "Disable contract",
    SureDisableContract: "Are you sure to disable this contract?",
    ContractEnabledCorrectly: "Contract enabled correctly",
    ContractDisabledCorrectly: "Contract disabled successfully",
    Contractor: {
      Contractors: "Subcontracts",
      Assign: "Assign subcontract",
      SureDelete:
        "Are you sure you want to delete the assignment of the contract and the active contract workers?",
    },
  },
  contracts: {
    ContractsDisabledWarning:
      "Attention!! Some workers are disabled (check them at the bottom of the page)",
    ContractsDisabled: "Disabled contracts",
    Saved: "Contract saved correctly ",
    SelectWorkCenter: "Select a workplace",
    SelectWorkCenterAndSubject: "Select a subject and a workplace",
    SelectSubject: "Select a subject",
    InForce: "Contracts in effect",
    New: "New contract",
    Edit: "Edit contract",
    Delete: "Delete",
    AssignServices: "Assign contract templates",
    SureDelete: "Are you sure you want to delete this contract?",
    NoAvailable: "No contract available",
    Deleted: "Contract deleted correctly ",
    WorkCenters: {
      Assign: "Assign a workplace",
      Saved: "Workplace assigned correctly ",
      SelectWorkCenter: "Select a workplace",
      Deleted: "Workplace deleted correctly ",
      Min:
        "A contract must have a workplace, assign one beforehand or delete the contract",
    },
    DocumentTypes: {
      Assign: "Add pre-defined documentary requirement",
      Add: "Add documentary requirement",
      Edit: "Edit documentary requirement",
      DocumentTypes: "Documentary requirements",
      NoAvailable: "No documentary requirement available",
      both: "Both",
      contract_of: "Contractor",
      subcontract_of: "Subcontractor",
      Delete: "Delete document type",
      Restore: "Restore the document type",
      SureDelete:
        "Are you sure you want to delete this documentary requirement?",
      SureRestore:
        "Are you sure you want to restore this documentary requirement with the general conditions?",
      Worker: "Worker documentary requirements",
      Company: "Company documentary requirements",
      Machine: "Work team documentary requirements",
      Saved: "Documentary requirement assigned correctly",
      Assigned: "Documentary requirements assigned:",
      Select: "Select a documentary requirement",
      Deleted: "Documentary requirement deleted correctly",
    },
    Companies: {
      AssignSub: "Assign subcontract",
      AssignableSub: "Assignable subcontracts",
      Subcontractors: "Subcontracts",
    },
    Workers: {
      Deleted: "Worker deleted from the contract correctly",
      FullyDisabledTitle: "Worker deactivated globally",
      FullyDisabledWarning:
        "This worker is deactivated globally, not just in the contract. If you want to activate a worker, you must activate it first in the list of workers",
      ShowDisabledWorkers: "Show deactivated workers",
      deactivate: "Deactivate",
      activate: "Activate",
    },
    Machines: {
      Deleted: "Work team deleted from the contract correctly",
    },
  },
  review: {
    Pending: "Pending review of documents",
    WorkerDocuments: "Worker documents",
    CompanyDocuments: "Business documents",
    MachineDocuments: "Work team documents",
    endDateBeforeInForceDate: "The end date is before the start date",
    MaxDocumentsShown:
      "Warning!! The maximum number of visible documents has been reached. Only the first 1000 will be shown.",
  },
  read: {
    Add: "Add document",
    Edit: "Edit document",
    Documents: "Information to read",
    Delete: "Delete document",
    SureDelete: "Are you sure you want to delete this document?",
    Uploaded: "Document added correctly",
    ErrorUpload: "Error adding document",
    Company: "Business documents",
    WorkCenter: "Workplace documents",
    WorkCenterRequired: "It is necessary to enter a workplace",
  },
  services: {
    Saved: "Template assigned correctly",
    NoSelection: "No template selected",
    Deleted: "Template deleted correctly",
    NoAvailable: "No templates available for assignment",
    NoAssigned: "No contract templates assigned",
    Assigned: "Contract templates assigned:",
  },
  workcenters: {
    Add: "Add workplace",
    Edit: "Edit workplace",
    Delete: "Delete workplace",
    WorkCenters: "Workplaces",
    Saved: "Workplace created correctly",
    Updated: "Workplace updated correctly",
    ObligatoryInfo: "Enter a valid name, address, and zip code",
    AssignTemplate: "Assign template to the workplace",
    DeleteAssignSure:
      "Are you sure you want to delete this workplace assignment?",
    DeleteAssign: "Delete assignment from the workplace",
    Deleted: "Workplace deleted correctly ",
    SureDelete: "Are you sure you want to delete this workplace?",
    NoSelect: "No workplace selected",
    NoAvailable: "No workplace available",
    ContractAccess: "Access the contract",
    WorkCentersClosed: "Work Centers closed",
    WorkCenterClosedWarning:
      "Attention!! Some work centers are closed (check them at the bottom of the page)",
    ClosedOn: "Closed on: ",
    CloseOn: "Close on: ",
  },
  workers: {
    Saved: "Worker created correctly",
    ObligatoryInfo: "Name, surname, NIF and email are mandatory",
    Workers: "Workers",
    WorkersDisabled: "Disabled workers",
    WorkersDisabledWarning:
      "Attention!! Some workers are disabled (check them at the bottom of the page)",
    Assign: "Assign worker",
    Save: "Worker assigned correctly",
    Select: "Select a worker",
    Add: "Add worker",
    AddFromITA: "Add workers from ITA",
    Delete: "Delete worker",
    SureDelete: "Are you sure you want to delete this worker?",
    Deleted: "Worker deleted correctly",
    Roles: "Roles",
    WorkerRoles: "Worker roles",
    NoAvailable: "No worker available",
  },
  templates: {
    Add: "Add template",
    Assigned: "Assigned templates:",
    AssignTemplates: "Assign templates",
    NoAssigned: "No templates assigned",
    Saved: "Template assigned correctly",
    Delete: "Delete template",
    NoAvailable: "No templates available for assignment",
    Deleted: "Template deleted correctly",
    SureDelete: "Are you sure you want to delete this template?",
    NoSelection: "No template selected",
    Company: "General templates",
    WorkCenter: "Workplace templates",
    Contract: "Contract templates",
    Contractor: "Company templates",
    Edit: "Edit template",
  },
  roles: {
    Add: "Add role",
    Edit: "Edit role",
    NewAssigned: "Role assigned correctly",
    Saved: "Role created correctly",
    Updated: "Role updated correctly",
    Duplicated: "Role duplicated correctly",
    Select: "Select a role",
    Deleted: "Role deleted correctly ",
    NoAvailable: "No role available",
    Assigned: "Roles:",
    Delete: "Delete role",
    SureDelete: "Are you sure you want to delete this role?",
    ObligatoryInfo: "Enter a valid name",
    Assign: "Add predefined role",
    RolesDefinedIndustrialProperty: "Roles defined by industrial property",
    PredefinedRoles: "Predefined roles",
    WorkerNoAssignedRole: "This worker has no assigned role",
    AtLeastOneRole: "There must be at least one role for this worker",
    documentTypes: {
      DocumentTypes: "Documentary requirements",
      Assigned: "Documentary requirements assigned:",
      NoAvailable: "There are no documentary requirements",
      Available: "Documentary requirements:",
      Saved: "Documentary requirement assigned correctly",
      Select: "Select a documentary requirement",
      Deleted: "Documentary requirement deleted correctly",
    },
  },
  functions: {
    Add: "Add team role",
    Edit: "Edit team role",
    NewAssigned: "Team role assigned correctly ",
    Saved: "Team role created correctly ",
    Updated: "Team role updated correctly ",
    Duplicated: "Team role duplicated correctly ",
    Select: "Select a team role",
    Deleted: "Team role deleted correctly ",
    NoAvailable: "No team role available ",
    Assigned: "Team roles:",
    Delete: "Delete team role",
    SureDelete: "Are you sure you want to delete this team role?",
    ObligatoryInfo: "Enter a valid name",
    Assign: "Add predefined team role",
    MachineNoAssignedRole: "This team has no assigned role",
    documentTypes: {
      DocumentTypes: "Documentary requirements",
      Assigned: "Documentary requirements:assigned:",
      NoAvailable: "There are no documentary requirements",
      Available: "Documentary requirements:",
      Saved: "Documentary requirement assigned correctly",
      Select: "Select a documentary requirement",
      Deleted: "Documentary requirement deleted correctly",
    },
  },
  versions: {
    Versions: "Versions",
  },
  label: {
    Actions: "Actions",
    Subject: "Subject:",
    WorkCenter: "Workplace",
    Worker: "Worker:",
    WorkerLower: "worker",
    WorkersLower: "workers",
    Machine: "Work team:",
    MachineLower: "work team:",
    MachinesLower: "work teams",
    Company: "Company:",
    CompanyContractor: "Client company:",
    Name: "Name:",
    ValidDays: "Required reading before",
    ValidDaysPost: " days",
    Type: "Type:",
    Destiny: "Destination:",
    Login: "Login:",
    Mail: "Mail:",
    Surname1: "Last Name 1:",
    Surname2: "Last Name 2:",
    CIF: "CIF:",
    ResponsableCAE: "CAE employee email:",
    Contact: "Contact:",
    NIF: "NIF/NIE",
    Password: "Password:",
    InForceDate: "Start date",
    EndDate: "End date",
    Expire: "Expires:",
    CanExpire: "Does this documentary requirement have an expiration?",
    Expiration: "Expiration:",
    Months: "months",
    Days: "days",
    ExpirationMode: "Mode:",
    Address: "Address:",
    Phone: "Telephone:",
    SearchPlaceHolder: "Search expression",
    File: "Archive:",
    Critical: "Critical:",
    ZipCode: "C.P.:",
    Population: "City:",
    Province: "Province:",
    Close: "End",
    Contract: "Contract",
    Contracts: "Contracts:",
    Contractor: "Contractor",
    DocumentTypesAvailable: "Types of documents to assign:",
    RolesAvailable: "Roles to assign:",
    ReviewCriteria: "Observations for the validator",
    ContractorCriteria: "Observations for the contract",
    CompaniesAvailable: "Companies to assign:",
    ServicesAvailable: "Templates to assign:",
    TemplatesAvailable: "Templates to assign:",
    Applicant: "Applicant:",
    Picture: "Photo:",
    Profile: "Available profiles:",
    ADMINISTRADOR_Description:
      "Allows access to all the industrial property and contractor options ",
    CONTRATISTA_Description:
      "Allows one to correct requirements, manage contracts and define workers and work teams",
    VALIDADOR_Description:
      "Allows one to review and validate the documentation provided by the contracts",
    Logo: "Logo:",
    Disabled: "Disabled",
    Roles: "Roles",
    Role: "Role:",
    Bia360: "Client:",
    PI: "Client company:",
    AutomaticType: "Automatic type:",
    Reference: "Reference:",
    ShowDisbledWorker: "Show disabled workers",
    MultipleRecipients: "Correct all applicants",
    Autonomo: "Self-employed:",
    Tolerate: "Tolerance:",
    StillValidUntil: "Still valid until:",
    MandatoryWorkerRoles: "Mandatory assignment of roles to workers",
    ExamineContracts:
      "To examine the list of assigned contracts, it is necessary to click on each of the elements that appear collapsed below:",
    ExamineWorkers:
      "To examine the list of assigned workers, it is necessary to click on each of the elements that appear collapsed below:",
    ExamineMachines:
      "To examine the list of assigned work teams, it is necessary to click on each of the elements that appear folded below:",
    Activate: "Activate",
    Deactivate: "Deactivate",
    MonthlyExpiration: "Monthly expiration:",
    Day1EachMonth: "Day 1 of each month",
    FromDateIndicated: "From the date indicated",
    Language: "Language:",
  },
  expireMode: {
    natural: "Calendar month",
    encurso: "Current month",
    fecha: "End date",
  },
  automaticType: {
    none: "It is not automatic",
    ita: "ITA",
    tc2: "TC2",
    rnt: "RNT",
    ss: "Current SS payment certificate",
    aeat: "Current AEAT payment certificate",
  },
  buttons: {
    Add: "Add",
    Create: "Create",
    Delete: "Delete",
    Restore: "Restore",
    Edit: "Edit",
    Assign: "Assign",
    Deny: "Deny",
    Approve: "Approve",
    Ok: "OK",
    Cancel: "Cancel",
    Save: "Save",
    Token: "Send documentary requirements",
    Credentials: "Send access credentials",
    Send: "Send",
    Duplicate: "Duplicate",
    Roles: "Roles",
    unlink: "Unlink",
    ModeAction: {
      expanded: "Collapse all",
      collapsed: "Expand all",
    },
    Previous: "Previous",
    Next: "Next",
  },
  placeholders: {
    Name: "Name",
    SearchContractAndWorkCenter: "Search by contract name or workplace",
    SearchNameCIF: "Search by company name or CIF",
    SearchNameSurnameDNI: "Search by worker or work team",
    SearchNameSurnameDNIRol: "Search by name, last name, ID or role",
    SearchMachineNameReference: "Search by name or reference",
    SearchName: "Search by name",
    SearchContractorsAndSubContractors:
      "Search by name of contractors and subcontractors",
    SearchUserMailCompany: "Search by username, email or company",
    SearchNameAndDocumentType: "Search by role name and document type",
    SearchNameAndDocumentTypeFunction:
      "Search by team role name and document type",
    SearchNameReference: "Search by company name or reference",
    SearchWorkerCompanyNameCIF: "Search by worker name, company or CIF",
    SearchWorkerMachineCompanyName: "Search by worker, work team or company",
    SearchCompanyContractWorkcenterContract:
      "Search by company, contract or workplace",
  },
  status: {
    Invalid: "invalid documents",
    Allowed: "Allowed",
    NotAllowed: "Not allowed",
  },
  pendingReading: {
    Warning: "Attention",
    title: "You have pending  information to read",
    description:
      "In order to continue managing your documentation, you must read and understand the following information:",
    Download: "Download",
    confirmationTitle: "Confirmation of read document",
    confirmationButton: "I confirm reading the document",
    confirmationDescription:
      "Do you confirm that you have read and accept this document?",
    mandatoryReading: "Mandatory reading documentation",
    mandatoryReadingAdvice:
      "In order to continue managing your documentation, you must read and understand the following information:",
    readingInNextDays: "Documentation to read in the future",
    readingInNextDaysAdvice:
      "Reading the following information does not block access to Bia360 until the defined period expires",
    deadline: "Reading period",
    days: "days",
    pendingReadingAdvice: "Attention: You have pending  information to read",
    delayReading: "Read documentation later",
    readingConfirmationOne: "Confirmation of read document",
    readingConfirmationByMe: "I confirm reading the document",
    readingConfirmationTwo:
      "Do you confirm that you have read and accept this document?",
  },
  documentTypes: {
    nameRequired: "The name is mandatory",
    expiredRequired:
      "If expiration is in progress, you must indicate the number of months (value must be greater than or equal to 1)",
    destinyRequired: "Must mark at least one destination",
    models: "Example models",
    Saved: "Type of document saved correctly",
    Deleted: "Type of document deleted correctly",
    Restored: "The default values have been restored",
    assign: "Assign",
    alreadyAssignedTitle: "Requirements in more templates",
    alreadyAssignedWarning:
      "The following requirements have already been assigned to another template. Do you want to assign them to this anyway?:",
  },
  tag: {
    ok: "VALID",
    valid: "VALID",
    expired: "EXPIRED",
    incorrect: "REJECTED",
    invalid: "INVALID",
    read: "TO READ",
    review: "IN REVIEW",
    reviewok: "IN REVIEW",
    empty: "NO CONTENT",
    client: "CLIENT COMPANY",
    tolerate: "TOLERANCE",
    selfEmployed: "Self-employed",
  },
  warnings: {
    deny:
      "Are you sure you want to deny the documentary requirement without indicating a reason?",
    denyFirstChunk: "Are you sure you want to",
    denyLastChunk: "the documentary requirement without indicating a reason?",
  },
  dates: {
    select: "Enter date",
  },
  texts: {
    createdBy: "created by",
    disassociateCompany: "Unlink company",
    disassociate: "Unlink ",
    disassociateConfirm: "Are you sure you want to unlink this company?",
    noAffectedContracts:
      "The contracts to which you are currently assigned will not be affected",
    linked: "linked",
    pending: "pending",
    contractLinkAuthByMeChunk:
      "to those that you have authorized to be registered in your contracts",
    companiesAlready360customers:
      "Companies that are already Bia360 customers and",
    contractLinkAuthToMeChunk:
      "that have given their authorization to be used in contracts",
    lastLog: "Last record",
    fullAccessLog: "Full access record",
    searchAccessAdvice:
      "Use the search field to consult worker and work teams access",
    documentDenied: "Rejected document",
    applicantLabel: "Requested by",
    applicantLabelLower: "requested by",
    documentVersions: "Requirement versions",
    uploadedBy: "Uploaded by",
    document: "document",
    documents: "documents",
    close: "Close",
    updatedData: "Updated data",
    machineFunction: "Type of team",
    showDisabledContracts: "Show deactivated contracts",
    contractorMaxs: "Maximum per contract",
    accessToRequirement: "Access the requirement",
    processedDocuments: "Processed documents",
    loading: "loading ...",
    property: "Industrial property",
    propertyAcronym: "PI",
    reviewAndValidation: "Revision and validation of requirements",
    hideRejected: "Hide the rejected requirements",
    haveProcessed: "They have been processed",
    accept: "Accept",
    warning: "Aviso",
    passwordPlaceHolder: "Only if the uploaded PDF requires a password",
    selectWorkCenter: "Select a work center",
    by: "by",
    from: "from",
    to: "to",
  },
  linked: {
    requestLinKChunk: "Request links from",
    propertyClient: "client company",
    contractor: "contract",
    canCreateContractToMeChunk:
      "you can register your contracts and request the necessary documentation.",
    canCreateContractByMeChunk:
      "you can register your contracts and require documentation.",
    existentClientWarning:
      "Attention!!: The company you want to register is already a client of Bia360",
    linkExplanationStarterChunk:
      "You can work with them by sending them an application. As soon as the company approves your application,",
    linkQuestion: "Do you want to apply with this company?",
    agree: "I agree",
  },
  profiles: {
    warning: "NOTICE",
    defaultProfilesWarningLine1:
      "If no profile is selected, the user will have access to the access control query",
    defaultProfilesWarningLine2: "and to contractor information.",
    administrator: "ADMINISTRATOR",
    contractor: "CONTRACTOR",
    validator: "VALIDATOR",
  },
  icons: {
    accessControl: "Access control icon",
    dashboard: "Dashboard icon",
    configuration: "Configuration icon",
    documentation: "Documentation icon",
    adminstration: "Administration icon",
    contracts: "Contracts icon",
    contractor: "Contractor icon",
    readings: "Documentation to read icon ",
    contact: "Contact icon",
  },
  inPlant: {
    resources: "Plant resources",
    noResourcesInPlant:
      "Currently there is no worker or work team in the plant",
  },
  temporaryAuthorizations: {
    authorizations: "Temporary authorizations",
    noTemporaryAuthorizations: "Currently there is no temporary authorization",
    new: "New authorization",
    newAuthorization: "New temporary authorization",
    startDate: "Start date",
    endDate: "End date",
    reason: "Reason for temporary authorization",
    startAuthorization: "Start",
    endAuthorization: "End",
    autocomplete: "Enter worker's name or CIF",
    delete: "Delete authorization",
    sureDelete: "Are you sure you want to delete this authorization?",
    deletedAuthorization: "Deleted authorization",
    errorDeleting: "Error deleting the authorization",
  },
  accesses: {
    registerEntrance: "Check in",
    registerExit: "Check out",
    registeredEntrance: "Registered entry",
    registerdExit: "Registered exit",
    noLogsFound:
      "No access records of workers and work teams with the current criteria have been found.",
    access: "Access",
    entity: "Worker/ Work team",
    company: "Company",
    loggedBy: "Registered by",
    dates: "Dates",
    logTitle: "Access registry of workers and work teams",
    entrance: "Entry",
    exit: "Exit",
    duplicateEntranceWarning:
      "This worker shows that he has already entered in the last 24 hours and there is no proof that he has left. Is it correct to register his entry again?",
    duplicatedEntrance: "Entrada duplicada",
    exportToExcel: "Export to Excel",
    onlyWithoutDocumentationPresented: "No OK entry",
    workerDocuments: "Worker documents",
    machineDocuments: "Work team documents",
  },
  contact: {
    helpQuestion: "Do you need help?",
    contactWithUs:
      "Contact us and the BIA360 CAE team will resolve all your issues",
    contactInformation: "This is our contact information",
    email: "Email",
    phone: "Telephone",
  },
  rolesblock: {
    blockExplanationFirstParagraf:
      "By default, the contractor can assign roles to its workers .",
    blockExplanationSecondtParagraf:
      "It is possible to block this functionality so that the roles of the contract workers can only be managed by the industrial property.",
    allowManagment: "Allow management of contractor roles",
  },
  assignment: {
    unassignWorker: "Un-assign worker",
    unassign: "Un-assign",
    confirmUnassigment:
      "Are you sure you want to un-assign this contract worker?",
    unassignmentAdviceFirstChunk: "The contract worker will be unassigned",
    unassignmentAdviceSecondChunk: "and all your documentation will be deleted",
    unassignmentAdviceThirdChunk: "relative to this contract",
  },
  readings: {
    loading: "Loading visualizations ...",
    noCompanies: "There are no companies that need to read this document",
    visualizations: "Visualizations",
    unread: "Unread",
    read: "Read",
    on: "on",
  },
  expirableDocuments: {
    filterAdvice:
      "You are only seeing the requirements of a particular type of document.",
    showAll: "See all",
    loading: "Loading documents ...",
    noExpirableDocuments: "There are no documents about to expire",
    title: "Documents about to expire",
  },
  newfeatures: {
    title: "News",
    read: "Viewed",
    advice: "News!",
  },
  stats: {
    title: "Bia360 usage statistics",
    documentValidations: "Validations of documents",
    validateOrRejected:
      "Documents validated or rejected during the review process",
    validatedByPI: "Validated by the industrial property",
    validatedByBia360: "Validated by the Bia360 team",
  },
  auth: {
    passwordDontMatch: "The passwords do not match",
    passwordChanged: "Password changed correctly",
    userData: "User data",
    userName: "Username",
    email: "Email",
    password: "Password",
    changePassword: "Change password",
    passwordChange: "Password change",
    save: "Save",
    newPassword: "New password",
    repeatPassword: "Repeat password",
    logout: "Disconnect",
  },
  token: {
    tokenExpiredAdvice:
      "The facilitated access address has expired. Please ask your contractor to generate a new one.",
    requirementsGeneratedFor: "Documentary requirements generated for",
    exportedDocumentFor: "Document exported by",
    withCIF: "with CIF",
    correctUntil: "Valid until",
    activeCredentials: "ACTIVE",
    inactiveCredentials: "INACTIVE",
    sentOn: "Sent on",
    numberOfRecipients: "Number of recipients",
    noShipmentDocumentation: "No shipment of documentation has been made",
    noShipment: "No shipment has been made",
  },
  assignedWorkers: {
    allContractsAssigned:
      "All available contracts have already been assigned to this worker",
    noContractAssigned: "No contract has been assigned to this worker",
    blockedAdvice:
      "This option is limited by the industrial property, please contact them with any questions",
    unassignWorker: "Un-assign worker",
    unassign: "Un-assign",
    correctAssignment: "Worker assigned correctly",
    maxWorkersReached:
      "The allocation exceeds the maximum of workers in the following contracts",
    assignToAllContracts: "Assign to all contracts",
    unassignedOK: "Worker unassigned from the contract correctly",
    warning:
      "Attention!! Some contracts have been deactivated by industrial property and will not allow the upload of documents:",
    thereIsContractWithMandatoryRoles:
      "For any of the contracts it is mandatory to specify a role. The worker will not be assigned to any contract",
  },
  denyReasons: {
    specifyReasonFisrtChunk: "Specify a reason for",
    denyArticle: "the",
    denyName: "denial",
    denyVerb: "deny",
    invalidateArticle: "the",
    invalidateName: "invalidation",
    invalidateVerb: "invalidate",
  },
  "rc-calendar": {
    today: "Today",
    now: "Now",
    backToToday: "Back to today",
    ok: "Accept",
    clear: "Clean",
    month: "Month",
    year: "Year",
    timeSelect: "Select time",
    dateSelect: "Select date",
    monthSelect: "Choose a month",
    yearSelect: "Choose a year",
    decadeSelect: "Choose a decade",
    yearFormat: "YYYY",
    dateFormat: "DD/MM/YYYY",
    dayFormat: "DD",
    dateTimeFormat: "DD/MM/YYYY HH:mm:ss",
    monthFormat: "MMMM",
    monthBeforeYear: true,
    previousMonth: "Last mont (PageUp)",
    nextMonth: "Next Month (PageDown)",
    previousYear: "Last year (Control + left)",
    nextYear: "Next year (Control + right)",
    previousDecade: "Lat decade",
    nextDecade: "Next decade",
    previousCentury: "Last century",
    nextCentury: "Next century",
  },
  dataagreement: {
    dataAccess:
      "Acceptance of conditions General Data Protection Regulation (GDPR)",
    continue: "Continue",
    explanation:
      "In order to exercise the role of data processing officer, Bia360 requires the following information in order to generate the treatment contract.",
    agreementNeeded:
      "Your acceptance is necessary in order to continue providing the service.",
    representativeFullName: "Legal Representative (full name)",
    representativeDNI: "DNI of the legal representative",
    companyAddress: "Company address",
    accept: "I agree",
    pdfDownload: "Download PDF",
  },
  search: {
    areMoreResults: "Showing only 10 results",
  },
  requirement_names: {
    "1193": "Designation of Preventive Resources",
    "1197": "Constitution Act of Own Prevention Service",
    "1204": "Instruction book",
    "1205": "Vehicle registration certificate",
    "2430": "Specific training in Road Safety",
    "2960": "Training on chemical risks in workplace",
    "4481": "Information according to RD396/2006 (exposure to asbestos)",
    "9700": "Health and Safety Plan (HSP)",
    "1198": "Certificate of Statutory Audit Own Prevention Service",
    "12900": "Appointment of Heath and Safety Coordinator",
    "4590774":
      "2nd Training Cycle. Installer of furniture and carpentry pieces",
    "12905": "Training/skills certificate for welding work",
    "4590776": "Training in assembling Safety Networks",
    "12909":
      "Information on risks and measures identified in the Health and Safety Plan",
    "4482":
      "Work plan for removal or maintenance operations of elements with asbestos",
    "12910":
      "Training on risks and measures identified in the Health and Safety Plan",
    "3312270": "Registration in the Social Security System (TA2)",
    "4484": "Training as Occupational Risk Prevention Technician",
    "4485": "Training as Heath and Safety Coordinator",
    "12903": "Crane operator licence",
    "12906": "Training in scaffolding assembly",
    "12913": "Compulsory review certificate by the OCA",
    "12915": "ATEX equipment technical specifications",
    "15606": "Type-approval certificate of fibre cement decontamination booth",
    "1209": "Registered Workers Report (ITA)",
    "15594": "Training Agreement 2nd cycle. Steel framework",
    "4590777": "Training in Concrete Pumps operation",
    "4483": "Registration in RERA (Asbestos Risk Register)",
    "15607": "Act of approval of the Health and Safety Plan",
    "15608": "Opening of the Work Centre",
    "15595": "Training Agreement 2nd cycle. Formwork",
    "3453202": "Training Agreement in Metal Ironwork.",
    "15609": "Logbook",
    "4349202": "Rescue plan in confined spaces",
    "15611": "Subcontracting Book",
    "15612": "Adhesion to the Health and Safety Plan (HSP)",
    "15613": "Designation of Preventive Resources",
    "15614": "Authorization of waste Managers/Transporters",
    "15615": "Waste acceptance document",
    "15616":
      "Register of installation/maintenance companies of fire protection systems (RECI)",
    "15617":
      "Official register of establishments and services of biocides (ROESB)",
    "15618":
      "Official register of establishments and services of pesticides (ROESP)",
    "15619": "Specific work procedure",
    "15603": "Training of middle managers 20h (Agreement)",
    "15604":
      "Training of work managers and implementation technicians 20h (Agreement)",
    "1098": "Medical certificate of fitness for the work carried out",
    "15592": "Training Agreement 2nd cycle. Insulation and waterproofing",
    "15593":
      "Training Agreement 2nd cycle. Special foundations, Boring and drilling",
    "15597": "Training Agreement 2nd cycle. Painting",
    "15598": "Training Agreement 2nd cycle. Demolition and refurbishment",
    "15599":
      "Training Agreement 2nd cycle. Supply and drainage networks, sewage",
    "15600": "Training Agreement 2nd cycle. Manual equipment operators",
    "15601": "Training Agreement 2nd cycle. Concrete prefabricated assembly",
    "15602":
      "Training Agreement 2nd cycle. Placing of covering materials (roofs, etc)",
    "330800": "ADR transport card",
    "330801": "ADR approval certificate of the vehicle",
    "330802": "Regular inspection certificate of the ADR vehicle",
    "3453203":
      "Training Agreement in Metal Oxy-fuel cutting and welding operators.",
    "307830":
      "TA.2/S - Application to register in the Social Security as an employee or similar (TEAs only)",
    "3312271": "2nd Training Cycle. Professions",
    "12901": "Driving licence",
    "3453208":
      "Training Agreement in Metal Preassembly operators, assembly, change of format and assembly in factories.",
    "3116640": "TC2/RNT (Nominal Employment List)",
    "4349200": "ISO 14001:2015 (Environmental management system)",
    "4349203": "Entry instructions in confined spaces",
    "4480": "Training in work with asbestos/fibre cement",
    "3453209": "Training Agreement in Metal Metallic carpentry work.",
    "3453470": "Training Agreement in Metal Jewellery work operators.",
    "3453200":
      "Training Agreement in Metal Manufacturing work, production and processing of iron, of steel and non-ferrous metals, and first processing",
    "3453201":
      "Training Agreement in Metal Building work, ship repair and maintenance in shipyards and docks.",
    "3453471":
      "Training Agreement in Metal Mechanical work, maintenance and repair of machinery, industrial equipment and/or electromechanical equipment",
    "3453204":
      "Training Agreement in Metal Operators of chip removal machining tools.",
    "3453205":
      "Training Agreement in Metal Operators of abrasion machining tools.",
    "3453206":
      "Training Agreement in Metal Operators of metal cutting and deformation machining tools.",
    "3453207":
      "Training Agreement in Metal Operators in surface treatments of degreased metal parts, cleaning, stripping, coating, painting",
    "3453472": "Training Agreement in Metal Work in vehicle repair workshops.",
    "3453473":
      "Training Agreement in Metal Installation work, maintenance and repair of computer equipment, automation and programming, computers and their peripherals or auxiliary devices; telecommunication and information technology and equipment, information and data networks (ICT)",
    "3453474":
      "Training Agreement in Metal Installers and repairers of electrical power lines and equipment.",
    "3453475":
      "Training Agreement in Metal Plumbing, heating-air conditioning installations, sanitary hot water installations and solar heat installations.",
    "3453476":
      "Training Agreement in Metal Maintenance work, repair and installation of lifts.",
    "3453477": "Training Agreement in Metal Insulation and waterproofing work",
    "3453478":
      "Training Agreement in Metal Assembly work of tubular structures.",
    "12904": "Pesticide/biocide applicator licence",
    "3453479":
      "Training Agreement in Metal Construction work and maintenance of railways.",
    "3453530":
      "Training Agreement in Metal Installation work, maintenance and repair of telecommunications infrastructures (ICT and digitalization)",
    "3453531":
      "Training Agreement in Metal Work on gas pipelines and combustible gas distribution networks.",
    "3453532":
      "Training Agreement in Metal Work on other types of installations, such as photovoltaic solar installations or wind power installations.",
    "3453533":
      "Training Agreement in Metal Work in recovering and recycling secondary metal raw materials.",
    "3453534":
      "Training Agreement in Metal Quality Control Work, monitoring and inspection of material under manufacture and on finished products of the sector.",
    "12897": "Qualified electrician certificate according to RD614/2001",
    "1202": "Civil Liability Insurance. Proof of payment",
    "3480860": "CAP",
    "1211": "Training Agreement 2nd cycle. Operator of lifting equipment",
    "1203": "Declaration of Conformity (CE Marking) or adaptation to RD1215",
    "4590772":
      "Training Agreement 2nd cycle. Stabilization of forecourts and paving",
    "3453535": "Training Agreement in Metal Drivers/carriers",
    "3453536": "Training Agreement in Metal Drivers of forklift trucks.",
    "3453537": "Training Agreement in Metal Operators of bridge cranes",
    "3453538": "Training Agreement in Metal Operator of lifting platforms",
    "3453539":
      "Training Agreement in Metal Warehouse and logistics operators and supply in manufacturing processes (including packaging, preparation of products, reprocessing, with or without the help of mechanical elements and other provision and supply work of materials and components).",
    "3453590":
      "Training Agreement in Metal Drivers of self-powered mobile cranes.",
    "15590": "Training in earth moving machinery",
    "12907":
      "Training in hygienic-health treatment in risk installations against legionella",
    "12898": "Training in handling forklift trucks",
    "15596":
      "Training Agreement 2nd cycle. Plumbing of air conditioning installations",
    "4349201": "Harness review certificate",
    "4172690": "DURC Documento unico di regolarità contributiva",
    "12902": "ADR licence",
    "4590775": "Training in Occupational Risk Prevention",
    "4590771": "European health insurance card",
    "4590773": "Food handling licence",
    "1201": "Civil Liability Insurance. Policy",
    "12908": "Training in self-loading handling",
    "1191": "Training Agreement 2nd cycle. Masonry",
    "1206": "Technical Vehicle Inspection (ITV or ITGA)",
    "434350": "Information on risks that may affect third parties",
    "4172691": "A1 certificate",
    "1192": "Training Agreement Basic Level 60-50h",
    "1096": "Specific ORP training for work at heights",
    "310060": "Training Agreement 2nd cycle. Plaster sheathing.",
    "1208": "Social Security Certificate (IGSS) of up-to-date payments",
    "12896": "Qualified electrician certificate according to RD614/2001",
    "1097": "Specific ORP training for work in confined spaces",
    "3550":
      "Training Agreement 2nd cycle. Installations, assemblies, metal structures",
    "12891": "Civil Liability Vehicle Insurance. Proof of payment",
    "1093": "Identity card (ID card, Foreigner ID card, Passport)",
    "12911": "Agreement Accident Insurance. Proof of payment",
    "12893": "Association to a Mutual Trust Document",
    "12892": "Chemical Safety Cards",
    "12914": "Technical specifications",
    "1194": "Authorization in the use of machinery-tools (Working equipment)",
    "1207": "Tax Agency Certificate (AEAT) of up-to-date payments.",
    "1196": "Preventive Modality Certificate adopted in the company",
    "12895": "TC2/RNT (Nominal Employment List)",
    "12912":
      "Certification of compulsory review of the work equipment, as established by the manufacturer",
    "12899": "Training in handling lifting platforms (PEMP)",
    "15605": "RETA Self-Employment Social Security. Proof of payment",
    "1195": "Risk Assessment of works to be done and preventive measures",
    "12890": "Agreement Accident Insurance. Policy",
    "15610":
      "Registration in the REA (Register of Accredited Companies of the Building Sector)",
    "1199": "Contract or certificate of the External Prevention Service",
    "4590770": "Vehicle insurance",
    "1190": "Training Agreement 2nd cycle. Electricity",
    "1095":
      "Training in occupational risk prevention of the workplace (art. 19 LPRL)",
    "1094": "Delivery of Personal Protective Equipment (PPE)",
    "1200": "Proof of up-to-date payment with the External Prevention Service",
    "1099": "Information on risks existing in the workplace (art. 18 LPRL)",
    "1090": "ITA Registered Workers Report (ITA)",
    "15591":
      "Training Agreement 2nd cycle. Operators of earth moving vehicles and machinery",
    "11070": "TC1 / RLC (Contributions Settlement Receipt) + Proof of payment",
  },
};
