export default {
  automatic: {
    Title: "Traitement automatique des conditions documentaires",
    Found: "Les travailleurs suivants ont été trouvés dans le document :",
    Processed:
      "On a couvert la condition documentaire des travailleurs suivants :",
    NotProcessed:
      "Les travailleurs suivants ne se trouvent pas enregistrés ou n'ont pas cette condition documentaire :",
    Add: {
      Title: "Insertion automatique des travailleurs",
      Processed: "Les travailleurs suivants ont été insérés :",
      NotProcessed:
        "Les travailleurs suivants existaient déjà ou on a trouvé des erreurs en les traitant :",
    },
    failedAutomaticDocWarningStart:
      "Le document n'a pas pu être automatiquement validé et",
    failedAutomaticDocWarningReview: "on l’a laissé en révision",
    failedAutomaticDocWarningEnd:
      "Ne pouvant pas être automatiquement validé, on l’applique seulement pour cette condition documentaire spécifique. Uniquement les documents téléchargés directement de la source originale et sans aucune manipulation pourront êtres traités automatiquement.",
    failedAutomaticDocWarningTitle:
      "Imposible de valider le document de façon automatique",
  },
  assigned: {
    Workers: "Travailleurs assignés",
    Machines: "Équipements assignés",
    Companies: "Contrats assignés",
  },
  access: {
    Access: "Contrôle d'accès des travailleurs et des équipements de travail",
    AccessTitle: "Contrôle d'accès",
    AccessWorkCenter: "Centre de travail",
    TotalWorkers: "travailleurs chargés",
    CompaniesSearch: "Entreprise",
    EntitiesSearch: "Travailleur/Equipement de travail",
    SwitchSearchLabel: "Recherche par",
    authorizedByHand: "Autorisé manuellement",
    worker: "Travailleur",
    Success: "Travailleur autorisé correctement",
    Error: "Erreur lors de l’autorisation du travailleur",
    SelectWorkerFirst: "Sélectionner premièrement un travailleur",
    ObligatoryInfo: "Tous les renseignements sont obligatoires",
  },
  unassigned: {
    Workers: "Travailleurs sans assigner",
    Machines: "Équipements de travail sans assigner",
  },
  exceptions: {
    Title: "Erreur ",
    "501": "Le document apporté n’a pas de format ITA",
    "503": "On n’a pas trouvé le travailleur dans le document apporté",
    "506": "On n’a pas trouvé la date d’enregistrement sur le document",
    "507":
      "Le SIRET du document ne correspond pas avec l’entreprise qui le demande",
    "508": "Le document à expiré",
    "509":
      "Le document proportionné n’est pas un certificat de mise à jour de payement de la Sécurité Sociale",
    "510": "Le document est périmé selon le type de d’expiration",
    "511": "Les dates introduites sont hors du rang valable",
    "512":
      "Le document proportionné n’est pas un certificat de mise à jour de payement de vos obligations tributaires",
    "513":
      "Le document apporté n’a pas de format de Liste de Travailleurs Salariés",
  },
  users: {
    Users: "Utilisateurs",
    Saved: "Utilisateurs ajoutés correctement",
    ObligatoryInfo: "Introduisez un e-mail et un mot de passe valables",
    Add: "Ajouter",
    AddIndustrialPropertyUser:
      "Ajouter l’utilisateur de propriété industrielle",
    AddContractUser: "Ajouter l’utilisateur de traitant",
    Edit: "Éditer l’utilisateur",
    Deleted: "L’utilisateur a été correctement éliminé",
    SureDelete: "Êtes-vous sûr d’éliminé cet utilisateur ?",
    Delete: "Éliminer l’utilisateur",
    InvalidMail: "Courrier électronique pas valable",
    UserCreationError:
      "On n’a pas pu créer l’utilisateur. Vérifier s’il existait déjà",
    IndustrialPropertyUsers: "Les utilisateurs de propriété industrielle",
    ContractorUsers: "Les utilisateurs des traitants",
    LatestCredentialsSent: "Envoyé le ",
    CredentialsSentSuccessfully: "Accréditations envoyées correctement",
  },
  machines: {
    Saved: "Équipement de travail créé correctement",
    ObligatoryInfo: "Introduisez un nom et une référence",
    Machines: "Équipements de travail",
    Assign: "Assigner un équipement de travail",
    Save: "Équipement de travail assigné correctement",
    Select: "Sélectionnez un équipement de travail",
    Add: "Ajouter un équipement de travail",
    Delete: "Éliminer un équipement de travail",
    SureDelete: "Êtes-vous sûr d’éliminer cet équipement de travail ?",
    Deleted: "Équipement de travail éliminé correctement",
    Edit: "Éditer un équipement de travail",
    NoAvailable: "Aucun équipement de travail disponible",
  },
  score: {
    WorkCenters: "centres de travail",
    Contractors: "traitants",
    Documents: "documents",
    Workers: "travailleurs",
    DisabledWorkers: "inactifs",
    DescriptionType: "Conditions requises",
    WorkersType: "travailleur",
    CompaniesType: "entreprise",
    MachinesType: "équipe/équipement",
    Company: "entreprise",
    Machines: "équipes/équipements",
    Roles: "rôles",
    Contracts: "contrats",
  },
  menu: {
    Dashboard: "Tableau de bord",
    Statistics: "Statistiques",
    Contracts: "Contrats",
    Documentation: "Documentation",
    Review: "Révision et validation",
    Templates: "Pochoirs",
    DocumentsToRead: "Information à lire",
    Configuration: "Configuration",
    Workers: "Travailleurs",
    Users: "Utilisateurs",
    DocumentTypes: "Documentaires requises",
    WorkCenters: "Centres de travail",
    Roles: "Rôles des travailleurs",
    Functions: "Rôles des équipes de travail",
    Contractors: "Contractés du contrat",
    Companies: "Entreprises",
    ClientCompanies: "Entreprises clientes",
    Machines: "Équipements de travail",
    Admin: "Administration",
    DocumentsToCompanies: "Information aux traitants",
    AccessesTitle: "Accès",
    Access: "Contrôle d'accès",
    Accesses: "Registre d’accès",
    InPlant: "Ressources en usine",
    TemporaryAuthorizations: "Autorisations temporaires",
    NeedHelp: "Avez-vous besoin d’aide ?",
    admin: {
      Roles: "Rôles des travailleurs",
      Functions: "Rôles des équipes/équipements",
      Features: "Nouvelles fonctionnalités",
      Estimate: "Envoi de devis",
    },
  },
  dashboard: {
    workers: {
      Workers: "travailleurs",
      InvalidWorkersNotFound:
        "On n’a pas trouvé de travailleur avec des documents non valables",
      WorkersInvalidDocuments: "Documents non valables de travailleur",
      WorkerDocuments: "Rôles des travailleurs",
    },
    machines: {
      machines: "Équipes/Équipements de travail",
      InvalidMachinesNotFound:
        "On n’a pas trouvé d´équipe/équipement de travail avec des documents non valables",
      MachinesInvalidDocuments: "Documents non valables d’équipe/Équipement",
      MachinesDocuments: "Documents d’équipes/Équipements de travail",
    },
    company: {
      InvalidCompaniesNotFound:
        "On n’a pas trouvé d’entreprise avec des documents non valables",
      CompanyInvalidDocuments: "Documents non valables d’entreprise",
      CompaniesDocuments: "Documents d’entreprise",
    },
  },
  documents: {
    Uploaded: "Ajouté le",
    UploadedBy: "Ajouté par",
    InvalidatedBy: "Invalidé par :",
    Reason: "Motif :",
    ExpiredOn: "Périmé le",
    ExpiresOn: "Va périmer le",
    TolerateTo: "tolérance jusqu’au",
    Upload: "Ajouter",
    UploadModel: "Model",
    UploadDocumentTitle: "Satisfaire les conditions documentaires requises",
    Approved: "Document approuvé",
    Denied: "Document rejeté",
    DocumentDeniedReason: "Motif du rejet",
    Roles: "Rôles",
    AnyRole: "",
    ProvideReason: "Spécifiez un motif pour le rejet",
    Saved: "Document ajouté correctement",
    ErrorSaved: "Erreur en ajoutant le document",
    FileNotFound: "Vous devez indiquer un fichier valable",
    ExtensionNotFound:
      "Vous devez indiquer un fichier avec une extension valable",
    InForceNotFound: "Vous devez indiquer la date de vigueur",
    EndDateNotFound: "Vous devez indiquer la date d´échéance",
    InvalidCompanyDocuments: "Documents non valables d’entreprise",
    Versions: "Versions",
    Files: "Fichiers",
    RequiredFields: "Le nom et le fichier sont obligatoires",
    Critical: "CRITIQUE",
    Dates: "Période de validité",
    RequirementLabel: "Condition requise",
    CriticalDoc: "Document critique",
    Review: "Aller à la révision",
    Dashboard: "Aller au tableau de bord",
    MaxSizeDocument: "La taille du document est supérieure à la permise",
    StartDate: "Date du début",
    EndDate: "Date de fin",
    Origin: "Origine",
    OriginCOMPANY: "Pochoir général",
    OriginCONTRACTOR: "Pochoir d’entreprise",
    OriginWORK_CENTER: "Pochoir du centre de travail",
    OriginROLE: "Rôle du travailleur",
    OriginFUNCTION: "Rôle de l’équipe/équipement de travail",
    OriginCONTRACT: "Pochoir de contrat",
    ForceOverride: "Forcer l’invalidation",
    Watch: "Voir",
    viewer: {
      reduce: "réduire",
      increase: "augmenter",
      reset: "re-starter le zoom",
      rotateLeft: "pivoter à gauche",
      rotateRight: "pivoter à droite",
      adjust: "ajuster",
      download: "télécharger",
      ItsNotPossiblePreviewThisFile:
        "On ne peut pas générer la vue préalable de ce fichier",
      YouMustDownloadTheFileToOpenIt:
        "Vous devrez télécharger le fichier pour l’ouvrir",
    },
  },
  chat: {
    Chat: "Chat",
    Comments: "Commentaires",
    NewComment: "Écrivez un nouveau commentaire",
    Send: "Envoyer",
  },
  companies: {
    Created: "Entreprise créée correctement",
    Updated: "Entreprise actualisée",
    ObligatoryInfo: "Le nom et le SIRET sont obligatoires",
    Companies: "Entreprises",
    ClientCompanies: "Entreprises clientes",
    Add: "Ajouter l’entreprise",
    Edit: "Éditer l’entreprise",
    Delete: "Éliminer l’entreprise",
    DeletedError:
      "On n’a pas pu éliminer l’entreprise Il existe des information reliées",
    SureDelete: "Êtes-vous sûr d’éliminer cette entreprise ?",
    NoAvailable: "Il n’existe pas d’entreprise disponible",
    Assign: "Assigner l’entreprise",
    Saved: "Entreprise assignée correctement",
    Select: "Sélectionnez une entreprise",
    Deleted: "Entreprise éliminée correctement",
    NoAssigned: "Aucune entreprise assignée",
    Assigned: "Entreprises assignées",
    Token: "Envoi des conditions documentaires requises",
    InvalidMailAddresses: "Adresses de courrier non valables",
    InvalidMailAddressesText:
      "On n’a pas pu envoyer les conditions documentaires requises aux adresses de courrier suivantes :",
    SureToken:
      "Êtes-vous sûr de vouloir envoyer toutes conditions documentaires requises à l’entreprise cliente ?",
    TokenSent:
      "Lien de consultation des conditions requises envoyé correctement",
    Recipients: "Destinataires",
    InvalidMailAddress:
      "On n’a pas pu envoyer les conditions documentaires requises",
    RelatedCompanies: "Entreprises reliées",
  },
  contractor: {
    Assign: "Assigner un traitant",
    Contractors: "Traitants",
    Saved: "Traitant ajouté au contrat correctement",
    SelectCompany: "Sélectionnez une entreprise",
    Delete: "Éliminer un traitant",
    Deleted: "Traitant éliminé correctement",
    noContractorsDefined:
      "On n’a pas défini des adjudicataires pour le contrat actuel dans ce centre de travail",
    ShowDeactivatedCompanies: "Montrer les entreprises désactivées",
    MoreOptions: "D´autres options",
    LockRoles: "Bloquer les rôles",
    Yes: "Oui",
    No: "Non",
    DisableContract: "Désactiver le traitant",
    SureDisableContract: "Êtes-vous sûr de désactiver ce contrat ?",
    ContractEnabledCorrectly: "Traitant activé correctement",
    ContractDisabledCorrectly: "Traitant désactivé correctement",
    Contractor: {
      Contractors: "Sous-traitant",
      Assign: "Assigner sous-traitant",
      SureDelete:
        "C’est sûr que vous désirez éliminer l’assignation du traitant et des travailleurs du contrat actif ?",
    },
  },
  contracts: {
    ContractsDisabledWarning:
      "Attention ! Quelques traitants sont désactivés (revoir à la fin de la page)",
    ContractsDisabled: "Traitants déactivés",
    Saved: "Contrat gardé correctement",
    SelectWorkCenter: "Sélectionnez un centre de travail",
    SelectWorkCenterAndSubject:
      "Sélectionnez une affaire et un centre de travail",
    SelectSubject: "Sélectionnez une affaire",
    InForce: "Contrats en vigueur",
    New: "Nouveau contrat",
    Edit: "Éditer contrat",
    Delete: "Éliminer",
    AssignServices: "Assigner pochoirs de contrat",
    SureDelete: "Êtes-vous sûr d’éliminer ce contrat ?",
    NoAvailable: "Aucun contrat disponible",
    Deleted: "Le contrat a été correctement éliminé",
    WorkCenters: {
      Assign: "Assigner un centre de travail",
      Saved: "Centre de travail assigné correctement",
      SelectWorkCenter: "Sélectionnez un centre de travail",
      Deleted: "Centre de travail éliminé correctement",
      Min:
        "Un contrat doit avoir obligatoirement un centre de travail, assignez-en un ou éliminer le contrat ",
    },
    DocumentTypes: {
      Assign: "Ajouter les conditions documentaires prédéfinies requises",
      Add: "Ajouter les conditions documentaires requises",
      Edit: "Éditer les conditions documentaires",
      DocumentTypes: "Conditions documentaires requises",
      NoAvailable: "Aucune condition documentaire disponible requise",
      both: "Tous deux",
      contract_of: "Traitant",
      subcontract_of: "Sous-traitant",
      Delete: "Éliminer le type de document",
      Restore: "Restaurer le type de document",
      SureDelete:
        "Êtes-vous sûr d’éliminer cette condition documentaire requise",
      SureRestore:
        "Êtes-vous sûr de vouloir restaurer cette condition documentaire requise avec les conditions générales ?",
      Worker: "Conditions documentaires requises du travailleur",
      Company: "Conditions documentaires requises de l’entreprise",
      Machine:
        "Conditions documentaires requises de l’équipe/équipement de travail",
      Saved: "Condition documentaire requise assignée correctement",
      Assigned: "Conditions documentaires requises assignées :",
      Select: "Sélectionner les conditions documentaires requises",
      Deleted: "Condition documentaire requise éliminée correctement",
    },
    Companies: {
      AssignSub: "Assigner sous-traitant",
      AssignableSub: "Sous-traitant assignables",
      Subcontractors: "Sous-traitant :",
    },
    Workers: {
      Deleted: "Travailleur éliminé du contrat correctement",
      FullyDisabledTitle: "Travailleur désactivé de manière globale",
      FullyDisabledWarning:
        "Ce travailleur est désactivé de manière globale, pas seulement sur le contrat. Si vous désirer l’activer, on doit le faire depuis la liste des travailleurs",
      ShowDisabledWorkers: "Montrer les travailleurs désactivés",
      deactivate: "Désactiver",
      activate: "Activer",
    },
    Machines: {
      Deleted: "Équipe/équipement de Travail éliminé du contrat correctement",
    },
  },
  review: {
    Pending: "Révision de document en cours",
    WorkerDocuments: "documents des travailleurs",
    CompanyDocuments: "Documents d’entreprise",
    MachineDocuments: "Documents d’équipes/Équipements de travail",
    endDateBeforeInForceDate: "La date de fin est antérieure à celle de début",
    MaxDocumentsShown:
      "Attention ! On a activé le nombre maximum de documents visibles. On ne montrera que les 1000 premiers.",
  },
  read: {
    Add: "Ajouter un document",
    Edit: "Éditer un document",
    Documents: "Information à lire",
    Delete: "Éliminer un document",
    SureDelete: "C’est sûr que vous voulez éliminer ce document ?",
    Uploaded: "Document ajouté correctement",
    ErrorUpload: "Erreur en ajoutant le document",
    Company: "Documents d’entreprise",
    WorkCenter: "Documents du centre de travail",
    WorkCenterRequired: "Il est nécessaire d’indiquer un centre de travail",
  },
  services: {
    Saved: "Pochoir assigné correctement",
    NoSelection: "On n’a sélectionner aucun pochoir",
    Deleted: "Pochoir éliminée correctement",
    NoAvailable: "Il n’y a pas de pochoir disponible pour l’assignation",
    NoAssigned: "Il n’y a pas de contrat assigné",
    Assigned: "Pochoirs de contrat assignés :",
  },
  workcenters: {
    Add: "Ajouter un centre de travail",
    Edit: "Éditer un centre de travail",
    Delete: "Éliminer un centre de travail",
    WorkCenters: "Centres de travail",
    Saved: "Centre de travail créé correctement",
    Updated: "Centre de travail actualisé correctement",
    ObligatoryInfo:
      "Introduisez une dénomination, adresse, et code postal valables",
    AssignTemplate: "Assigner un pochoir au centre de travail",
    DeleteAssignSure:
      "Êtes-vous sûr d’éliminer cette assignation du centre de travail ?",
    DeleteAssign: "Éliminer l’assignation du centre de travail",
    Deleted: "Centre de travail éliminé correctement",
    SureDelete: "Êtes-vous sûr d’éliminer ce centre de travail ?",
    NoSelect: "Aucun centre de travail sélectionné",
    NoAvailable: "Aucun centre de travail disponible",
    ContractAccess: "Acceder au contrat",
  },
  workers: {
    Saved: "Travailleur créé correctement",
    ObligatoryInfo: "Le prénom, le nom, le SIRET et l´e-mail sont obligatoires",
    Workers: "Travailleurs",
    WorkersDisabled: "Travailleurs déactivés",
    WorkersDisabledWarning:
      "Attention ! Quelques travailleurs sont désactivés (les revoir à la fin de la page)",
    Assign: "Assigner travailleur",
    Save: "Travailleur assigné correctement",
    Select: "Sélectionnez un travailleur",
    Add: "Ajouter travailleur",
    AddFromITA: "Ajouter travailleurs depuis ITA",
    Delete: "Éliminer travailleur",
    SureDelete: "Êtes-vous sûr d’éliminer ce travailleur ?",
    Deleted: "Le travailleur a été correctement éliminé",
    Roles: "Rôles",
    WorkerRoles: "Rôles des travailleurs",
    NoAvailable: "Aucun travailleur disponible",
  },
  templates: {
    Add: "Ajouter un pochoir",
    Assigned: "Pochoirs assignés :",
    AssignTemplates: "Assigner pochoirs",
    NoAssigned: "Il n’y a pas de pochoirs assignés",
    Saved: "Pochoir assigné correctement",
    Delete: "Éliminer pochoir",
    NoAvailable: "Il n’y a pas de pochoir disponible pour l’assignation",
    Deleted: "Pochoir éliminée correctement",
    SureDelete: "Êtes-vous sûr d’éliminer ce pochoir ?",
    NoSelection: "On n’a sélectionné aucun pochoir",
    Company: "Pochoirs généraux",
    WorkCenter: "Pochoirs de centres de travail",
    Contract: "Pochoirs de contrat",
    Contractor: "Pochoirs d’entreprise",
    Edit: "Éditer un pochoir",
  },
  roles: {
    Add: "Ajouter un rôle",
    Edit: "Éditer un rôle",
    NewAssigned: "Rôle assigné correctement",
    Saved: "Rôle créé correctement",
    Updated: "Rôle actualisé correctement",
    Duplicated: "Rôle doublé correctement",
    Select: "Sélectionnez un rôle",
    Deleted: "Le rôle a été correctement éliminé",
    NoAvailable: "Aucun rôle disponible",
    Assigned: "Rôles :",
    Delete: "Éliminer rôle",
    SureDelete: "Êtes-vous sûr d’éliminer ce rôle ?",
    ObligatoryInfo: "Introduisez un nom valable",
    Assign: "Ajouter un rôle prédéfini",
    RolesDefinedIndustrialProperty:
      "Rôles définis par la propriété industrielle ",
    PredefinedRoles: "Rôles prédéfinis",
    WorkerNoAssignedRole: "Ce travailleur n’a aucun rôle assigné",
    AtLeastOneRole: "Il doit exister au moins un rôle pour ce travailleur",
    documentTypes: {
      DocumentTypes: "Conditions documentaires requises",
      Assigned: "Conditions documentaires requises assignées:",
      NoAvailable: "Il n’existe pas de conditions documentaires requises",
      Available: "Conditions documentaires requises :",
      Saved: "Condition documentaire requise assignée correctement",
      Select: "Sélectionnez une condition documentaire requise",
      Deleted: "Condition documentaire requise éliminée correctement",
    },
  },
  functions: {
    Add: "Ajouter un rôle d’équipe/équipement",
    Edit: "Éditer un rôle d’équipe/équipement",
    NewAssigned: "Rôle d’équipe/équipement assigné correctement",
    Saved: "Rôle d’équipe/équipement créé correctement",
    Updated: "Rôle d’équipe/équipement actualisé correctement",
    Duplicated: "Rôle d’équipe/équipement dupliqué correctement",
    Select: "Sélectionnez un rôle d’équipe/équipement",
    Deleted: "Rôle d’équipe/équipement éliminé correctement",
    NoAvailable: "Aucun rôle d’équipe/équipement disponible",
    Assigned: "Rôles d’équipe/équipement :",
    Delete: "Éliminer un rôle d’équipe/équipement",
    SureDelete: "Êtes-vous sûr d’éliminer ce rôle d’équipe/équipement ?",
    ObligatoryInfo: "Introduisez un nom valable",
    Assign: "Ajouter un rôle d’équipe/équipement prédéfini",
    MachineNoAssignedRole: "Cette/Cet équipe/équipement n’a aucun rôle assigné",
    documentTypes: {
      DocumentTypes: "Conditions documentaires requises",
      Assigned: "Conditions documentaires requises assignées:",
      NoAvailable: "Il n’existe pas de conditions documentaires requises",
      Available: "Conditions documentaires requises :",
      Saved: "Condition documentaire requise assignée correctement",
      Select: "Sélectionnez une condition documentaire requise",
      Deleted: "Condition documentaire requise éliminée correctement",
    },
  },
  versions: {
    Versions: "Versions",
  },
  label: {
    Actions: "Actions",
    Subject: "Affaire :",
    WorkCenter: "Centre de travail",
    Worker: "Travailleur :",
    WorkerLower: "travailleur",
    WorkersLower: "travailleurs",
    Machine: "Équipe/équipement de travail :",
    MachineLower: "équipe/équipement de travail",
    MachinesLower: "équipes/Équipements de travail",
    Company: "Entreprise :",
    CompanyContractor: "Entreprise cliente :",
    Name: "Nom :",
    ValidDays: "Lecture obligatoire avant le",
    ValidDaysPost: " jours",
    Type: "Type :",
    Destiny: "Destination :",
    Login: "Identifiant :",
    Mail: "E-mail :",
    Surname1: "Nom 1 :",
    Surname2: "Nom 2 :",
    CIF: "SIRET :",
    ResponsableCAE: "Courrier responsable CAE :",
    Contact: "Contact :",
    NIF: "nº carte identité",
    Password: "Mot de passe :",
    InForceDate: "Date de début",
    EndDate: "Date de fin",
    Expire: "Périmé :",
    CanExpire: "Cette condition documentaire requise a un délais ?",
    Expiration: "Expiration :",
    Months: "mois",
    Days: "jours",
    ExpirationMode: "Mode :",
    Address: "Adresse :",
    Phone: "Téléphone :",
    SearchPlaceHolder: "Affichage de recherche",
    File: "Fichier :",
    Critical: "Critique :",
    ZipCode: "Code Postal :",
    Population: "Ville :",
    Province: "Province/Département :",
    Close: "Fermeture",
    Contract: "Contrat",
    Contracts: "Contrats :",
    Contractor: "Traitant",
    DocumentTypesAvailable: "Type de documents à assigner :",
    RolesAvailable: "Rôles à assigner :",
    ReviewCriteria: "Observations pour celui/celle qui valide",
    ContractorCriteria: "Observations pour le traitant",
    CompaniesAvailable: "Entreprises à assigner :",
    ServicesAvailable: "Pochoirs à assigner :",
    TemplatesAvailable: "Pochoirs à assigner :",
    Applicant: "Déposant :",
    Picture: "Photo :",
    Profile: "Profils disponibles :",
    ADMINISTRADOR_Description:
      "Permet l’accès à toutes les option de la propriété industrielle et de l’adjudicataire",
    CONTRATISTA_Description:
      "Permet de résoudre les conditions requises, de générer des contrats et de définir des travailleurs et des équipes/équipements de travail",
    VALIDADOR_Description:
      "Permet de réviser et de valider la documentation apportée par les traitants",
    Logo: "Logo :",
    Disabled: "Désactivé",
    Roles: "Rôles",
    Role: "Rôle :",
    Bia360: "Client :",
    PI: "Entreprise cliente :",
    AutomaticType: "Type automatique :",
    Reference: "Référence :",
    ShowDisbledWorker: "Montrer les travailleurs désactivés",
    MultipleRecipients: "Résoudre dans tous les déposants",
    Autonomo: "Autonome:",
    Tolerate: "Tolérance :",
    StillValidUntil: "Valable jusqu’au :",
    MandatoryWorkerRoles: "Assignation obligatoire de rôles au travailleurs",
    ExamineContracts:
      "Pour examiner la liste des contrats assignés, il est nécessaire de cliquer sur chacun des éléments qui apparaissent à continuation :",
    ExamineWorkers:
      "Pour examiner la liste des travailleurs assignés, il est nécessaire de cliquer sur chacun des éléments qui apparaissent à continuation :",
    ExamineMachines:
      "Pour examiner la liste des équipes/équipements assignés, il est nécessaire de cliquer sur chacun des éléments qui apparaissent à continuation :",
    Activate: "Activer",
    Deactivate: "Désactiver",
    MonthlyExpiration: "Expiration mensuelle :",
    Day1EachMonth: "1er jour de chaque mois",
    FromDateIndicated: "À partir de la date indiquée",
    Language: "Langue :",
  },
  expireMode: {
    natural: "Mois naturel",
    encurso: "Mois en cours",
    fecha: "Date de fin",
  },
  automaticType: {
    none: "Pas de type automatique :",
    ita: "ITA",
    tc2: "Liste des travailleurs",
    rnt: "RNT",
    ss: "Certificat de mise à jour de payement de la Sécurité Sociale",
    aeat: "Certificat de mise à jour de payement de l’Agence Tributaire",
  },
  buttons: {
    Add: "Ajouter",
    Create: "Créer",
    Delete: "Éliminer",
    Restore: "Restaurer",
    Edit: "Éditer",
    Assign: "Assigner",
    Deny: "Dénier",
    Approve: "Approuver",
    Ok: "Accepter",
    Cancel: "Annuler",
    Save: "Garder",
    Token: "Envoyer les conditions documentaires requises",
    Credentials: "Envoyer les autorisations d’accès",
    Send: "Envoyer",
    Duplicate: "Dupliquer",
    Roles: "Rôles",
    unlink: "mettre un terme",
    ModeAction: {
      expanded: "Tous regrouper",
      collapsed: "Tous étendre",
    },
    Previous: "Précédant",
    Next: "Suivant",
  },
  placeholders: {
    Name: "Nom",
    SearchContractAndWorkCenter:
      "Chercher un nom de contrat ou de centre de travail",
    SearchNameCIF: "Chercher un nom d’entreprise ou un SIRET",
    SearchNameSurnameDNI: "Chercher un travailleur ou une/un équipe/équipement",
    SearchNameSurnameDNIRol:
      "Chercher par prénom, noms, numéro de carte d´identité ou rôle",
    SearchMachineNameReference: "Chercher par nom ou référence",
    SearchName: "Chercher par nom",
    SearchContractorsAndSubContractors:
      "Chercher par nom des traitants et sous-traitant",
    SearchUserMailCompany:
      "Chercher par nom d’utilisateur, courrier ou entreprise",
    SearchNameAndDocumentType: "Chercher par nom du rôle et type de document",
    SearchNameAndDocumentTypeFunction:
      "Chercher par nom du rôle de l’équipe/équipement et type de document",
    SearchNameReference: "Chercher par nom d’entreprise ou référence",
    SearchWorkerCompanyNameCIF:
      "Chercher un nom de travailleur, une entreprise ou un SIRET",
    SearchWorkerMachineCompanyName:
      "Chercher travailleur, équipe/équipement de travail ou entreprise",
    SearchCompanyContractWorkcenterContract:
      "Chercher entreprise, contrat ou centre de travail",
  },
  status: {
    Invalid: "Documents non valables",
    Allowed: "Permit",
    NotAllowed: "Interdit",
  },
  pendingReading: {
    Warning: "Attention",
    title: "Vous avez des informations non lues",
    description:
      "Pour pouvoir continuer dans la gestion de votre documentation il faut que vous lisiez et compreniez l’information suivante :",
    Download: "Télécharger",
    confirmationTitle: "Confirmation de lecture du document",
    confirmationButton: "Je confirme la lecture du document",
    confirmationDescription:
      "Vous confirmez avoir lu et vous acceptez le présent document ?",
    mandatoryReading: "Documentation de lecture obligatoire",
    mandatoryReadingAdvice:
      "Pour pouvoir continuer dans la gestion de votre documentation il faut que vous lisiez et compreniez l’information suivante :",
    readingInNextDays: "Documentation à lire prochainement",
    readingInNextDaysAdvice:
      "La lecture de l’information suivante ne bloque pas l’accès à Bia360 jusqu’à ce que le délais défini expire",
    deadline: "Délais de lecture",
    days: "jours",
    pendingReadingAdvice: "Attention : Vous avez des informations non lues",
    delayReading: "Lire la documentation plus tard",
    readingConfirmationOne: "Confirmation de lecture du document",
    readingConfirmationByMe: "Je confirme la lecture du document",
    readingConfirmationTwo:
      "Vous confirmez avoir lu et vous acceptez le présent document ?",
  },
  documentTypes: {
    nameRequired: "Le nom est obligatoire",
    expiredRequired:
      "Si vous indiquez le mode d’expiration en cours vous devez indiquer le nombre de mois (valeur supérieure ou égale à 1)",
    destinyRequired: "Vous devez marquer au moins une destination",
    models: "Model d’exemple",
    Saved: "Type de document gardé correctement",
    Deleted: "Type de document éliminé correctement",
    Restored: "On a restauré les valeurs par défaut",
    assign: "Assigner",
    alreadyAssignedTitle: "Conditions requises en plus de pochoirs",
    alreadyAssignedWarning:
      "Les conditions requises on déjà été assignées à d’autres pochoirs. Voulez-vous de toute façon les assigner à celui-ci ?",
  },
  tag: {
    ok: "VALABLE",
    valid: "VALABLE",
    expired: "PÉRIMÉ",
    incorrect: "REFUSÉ",
    invalid: "PAS VALABLE",
    read: "À LIRE",
    review: "EN RÉVISION",
    reviewok: "EN RÉVISION",
    empty: "SANS CONTENU",
    client: "ENTREPRISE CLIENTE",
    tolerate: "TOLÉRANCE",
    selfEmployed: "Autonome",
  },
  warnings: {
    deny:
      "Vous êtes sur de rejeter la condition documentaire requise sans indiquer le motif ?",
    denyFirstChunk: "Vous êtes sûr de",
    denyLastChunk: "la condition documentaire requise sans indiquer le motif ?",
  },
  dates: {
    select: "Introduisez une date",
  },
  texts: {
    createdBy: "créée par",
    disassociateCompany: "Éliminer cette entreprise",
    disassociate: "Mettre un terme",
    disassociateConfirm: "Êtes-vous sûr d’éliminer cette entreprise ?",
    noAffectedContracts:
      "Les contrats auxquels sont actuellement assignée ne se verront pas affectés",
    linked: "Reliée",
    pending: "en cours",
    contractLinkAuthByMeChunk:
      "auxquelles vous avez autorisés pour qu’ils vous inscrivent dans leurs contrats",
    companiesAlready360customers: "Entreprises déjà clientes de Bia360 et",
    contractLinkAuthToMeChunk:
      "qui on donnés leurs autorisation pour pouvoir être utilisés dans des contrats",
    lastLog: "Dernier registre",
    fullAccessLog: "Registre d’accès complet",
    searchAccessAdvice:
      "Utilisez l’emplacement de recherche pour consulter l’accès des travailleurs et des équipes/équipements de travail.",
    documentDenied: "Documentation refusée",
    applicantLabel: "Demandé par",
    applicantLabelLower: "demandé par",
    documentVersions: "Versions de la condition requise",
    uploadedBy: "Téléchargé par",
    document: "document",
    documents: "documents",
    close: "Fermer",
    updatedData: "Données actualisées",
    machineFunction: "type d’équipe/équipement",
    showDisabledContracts: "Montrer les contrats désactivés",
    contractorMaxs: "Maximum par traitant",
    accessToRequirement: "Acceder à la condition requise",
    processedDocuments: "Documents traités",
    loading: "téléchargent...",
    property: "Propriété industrielle",
    propertyAcronym: "PI",
    reviewAndValidation: "Révision et validation des conditions requises",
    hideRejected: "Cacher les conditions requises refusées",
    haveProcessed: "Traité",
    accept: "Accepter",
    passwordPlaceHolder: "Seulement si le PDF téléchargé exige un mot de passe",
    warning: "Avis",
    selectWorkCenter: "Sélectionnez un centre de travail",
    by: "par",
    from: "du",
    to: "au",
  },
  linked: {
    requestLinKChunk: "Demander un lien de",
    propertyClient: "Entreprise cliente",
    contractor: "traitant",
    canCreateContractToMeChunk:
      "on pourra vous inscrire dans leurs contrat et vous demander ainsi la documentation nécessaire.",
    canCreateContractByMeChunk:
      "Vous pourrez l’inscrire dans vos contrat et lui demander la documentation.",
    existentClientWarning:
      "Attention ! : L’entreprise que vous voulez inscrire est déjà cliente du Bia360",
    linkExplanationStarterChunk:
      "Vous pouvez travailler avec elle en lui envoient une demande. Dès que l’entreprise approuve votre demande,",
    linkQuestion: "Voulez-voue demander le lien avec cette entreprise ?",
    agree: "D’accord",
  },
  profiles: {
    warning: "AVIS",
    defaultProfilesWarningLine1:
      "Si on ne sélectionne aucun profil, l´utilisateur aura accès à la demande du control d’accès",
    defaultProfilesWarningLine2: "et à l’information aux adjudicataires",
    administrator: "ADMINISTRATEUR",
    contractor: "ADJUDICATAIRE",
    validator: "VALIDATEUR",
  },
  icons: {
    accessControl: "Icône du contrôle d'accès",
    dashboard: "Icône du tableau de bord",
    configuration: "Icône de configuration",
    documentation: "Icône de documentation",
    adminstration: "Icône d’administration",
    contracts: "Icône de contrats",
    contractor: "Icône d’adjudicataire",
    readings: "Icône de documentation à lire",
    contact: "Icône de contact",
  },
  inPlant: {
    resources: "Ressources en usine/industrie",
    noResourcesInPlant:
      "Actuellement il n’y a aucun travailleur ou équipe/équipement de travail en usine/industrie",
  },
  temporaryAuthorizations: {
    authorizations: "Autorisations temporaires",
    noTemporaryAuthorizations:
      "Actuellement il n’y a aucun autorisation temporaire",
    new: "Nouvelle autorisation",
    newAuthorization: "Nouvelle autorisation temporaire",
    startDate: "Date du début",
    endDate: "Date de fin",
    reason: "Motif de l’autorisation temporaire",
    startAuthorization: "Début",
    endAuthorization: "Fin",
    autocomplete: "Introduisez le nom ou le SIRET du travailleur",
    delete: "Éliminer l’autorisation",
    sureDelete: "Êtes-vous sûr de vouloir éliminer cette autorisation ?",
    deletedAuthorization: "Autorisation éliminée",
    errorDeleting: "Erreur en éliminant l’autorisation",
  },
  accesses: {
    registerEntrance: "Enregistrer l’entrée",
    registerExit: "Enregistrer la sortie",
    registeredEntrance: "Entrée enregistrée",
    registerdExit: "Sortie enregistrée",
    noLogsFound:
      "On a pas trouvé de registre d’accès des travailleurs et des équipes/Équipements de travail avec les critères actuels. ",
    access: "Accès",
    entity: "Travailleur/Equipement de travail",
    company: "Entreprise",
    loggedBy: "Enregistré par",
    dates: "Dates",
    logTitle: "Contrôle d'accès des travailleurs et des équipements de travail",
    entrance: "Entrées",
    exit: "Sorties",
    duplicateEntranceWarning:
      "Ce travailleur est déjà entré dans les dernières 24 heures et on n’a pas de registre de sortie, est-il correcte de re-enregistrer son entrée ?",
    duplicatedEntrance: "Entrée dupliquée",
    exportToExcel: "Exporter à Excel",
    onlyWithoutDocumentationPresented: "Entrées non OK",
    workerDocuments: "documents du travailleur",
    machineDocuments: "Documents d’équipes/Équipements de travail",
  },
  contact: {
    helpQuestion: "Avez-vous besoin d’aide ?",
    contactWithUs:
      "Contactez-nous et l’équipe CAE de Bia360 résoudra toutes les questions",
    contactInformation: "Celle-ci est notre information de contact",
    email: "Courrier électronique",
    phone: "Téléphone",
  },
  rolesblock: {
    blockExplanationFirstParagraf:
      "Par défaut, l’adjudicataire peut assigner des rôles à ses travailleurs.",
    blockExplanationSecondtParagraf:
      "Il est possible de bloquer cette fonctionnalité et que les rôles des travailleurs du traitant puissent être seulement traités depuis la propriété industrielle.",
    allowManagment: "Permettre la gestion des rôles de l’adjudicataire",
  },
  assignment: {
    unassignWorker: "Désactiver un travailleur",
    unassign: "Désactiver",
    confirmUnassigment:
      "Êtes-vous sûr de désactiver ce travailleur du contrat ?",
    unassignmentAdviceFirstChunk: "On désactivera le travailleur du contrat",
    unassignmentAdviceSecondChunk: "et on éliminera toute sa documentation",
    unassignmentAdviceThirdChunk: "en relation avec ce contrat",
  },
  readings: {
    loading: "Téléchargent les visualisations...",
    noCompanies: "Il n’y a pas d’entreprise qui doive lire ce document",
    visualizations: "Visualisations",
    unread: "Pas lu",
    read: "Lu",
    on: "le",
  },
  expirableDocuments: {
    filterAdvice:
      "Vous voyez seulement les conditions requises d’un type de document concret.",
    showAll: "Voir tous",
    loading: "Téléchargeant des documents...",
    noExpirableDocuments: "Il n’y a pas de documents près à périmer",
    title: "Documents près à périmer",
  },
  newfeatures: {
    title: "Nouveautés",
    read: "Vu",
    advice: "Nouveautés !",
  },
  stats: {
    title: "Statistiques d’utilisation du Bia360",
    documentValidations: "Validation de documents",
    validateOrRejected:
      "Documents valables ou refusés pendant le processus de révision",
    validatedByPI: "Validé par la propriété industrielle ",
    validatedByBia360: "Validé par l’équipe du Bia360",
  },
  auth: {
    passwordDontMatch: "Les mots de passe ne coïncident pas",
    passwordChanged: "Mot de passe changé correctement",
    userData: "Données de l’utilisateur",
    userName: "Nom d’utilisateur",
    email: "Courrier électronique",
    password: "Mot de passe",
    changePassword: "Changer mot de passe",
    passwordChange: "Changement du mot de passe",
    save: "Garder",
    newPassword: "Nouveau mot de passe",
    repeatPassword: "Répéter le mot de passe",
    logout: "Déconnecter",
    principalCompany: "Entreprise principale",
    selectCompany: "Aucune entreprise sélectionnée...",
  },
  token: {
    tokenExpiredAdvice:
      "L’adresse d’accès est périmée. Demander, s.v.p., à votre adjudicataire de générer une nouvelle adresse.",
    requirementsGeneratedFor: "Conditions documentaires requises générées pour",
    exportedDocumentFor: "Document exporté par",
    withCIF: "avec SIRET",
    correctUntil: "Valable jusqu’au :",
    activeCredentials: "ACTIF",
    inactiveCredentials: "INACTIF",
    sentOn: "Envoyé le",
    numberOfRecipients: "Numéro de destinataires",
    noShipmentDocumentation: "Aucun envoi de documentation à été réalisé",
    noShipment: "Aucun envoi à été réalisé",
  },
  assignedWorkers: {
    allContractsAssigned:
      "Tous les contrats disponibles ont déjà été assignés pour ce travailleur",
    noContractAssigned: "Aucun contrat n’a été assigné à ce travailleur",
    blockedAdvice:
      "Cette option est limitée par la propriété industrielle, s.v.p., mettez-vous en contact avec elle pour toutes questions",
    unassignWorker: "Désactiver un travailleur",
    unassign: "Désactiver",
    correctAssignment: "Travailleur assigné correctement",
    maxWorkersReached:
      "L’assignation surpasse la limite des travailleurs dans les contrats suivants",
    assignToAllContracts: "Assigner à tous les contrats",
    unassignedOK: "Travailleur éliminé du contrat correctement",
    warning:
      "Attention ! Quelques contrats on été désactivés par la propriété industrielle et ne permettrons pas le téléchargement de documents :",
    thereIsContractWithMandatoryRoles:
      "Pour certains contrats il est obligatoire de spécifier un rôle. On n’assignera le travailleur à aucun contrat",
  },
  denyReasons: {
    specifyReasonFisrtChunk: "Spécifiez un motif pour",
    denyArticle: "la",
    denyName: "désignation",
    denyVerb: "dénier",
    invalidateArticle: "la",
    invalidateName: "invalidation",
    invalidateVerb: "invalider",
  },
  "rc-calendar": {
    today: "Aujourd’hui",
    now: "Maintenant",
    backToToday: "Retourner à aujourd’hui",
    ok: "Accepter",
    clear: "Nettoyer",
    month: "Mois",
    year: "Année/An",
    timeSelect: "Sélectionner l’heure",
    dateSelect: "Sélectionner la date",
    monthSelect: "Choisir le mois",
    yearSelect: "Choisir l’année",
    decadeSelect: "Choisir la décennie",
    yearFormat: "AAAA",
    dateFormat: "JJ/MM/AAAA",
    dayFormat: "JJ",
    dateTimeFormat: "JJ/MM/AAAA HH:mm:ss",
    monthFormat: "MMMM",
    monthBeforeYear: true,
    previousMonth: "Mois précédant (PageUp)",
    nextMonth: "Mois suivant (PageDown)",
    previousYear: "Année précédente (Control + left)",
    nextYear: "Année suivante (Control + right)",
    previousDecade: "Décennie précédente",
    nextDecade: "Décennie suivante",
    previousCentury: "Siècle précédent",
    nextCentury: "Siècle Suivant",
  },
  dataagreement: {
    dataAccess:
      "Acceptation des conditions du Règlement Général de Protection des données (RGPD) ",
    continue: "Continuer",
    explanation:
      "Afin d'exercer le rôle de chargé du traitement des données, Bia360 nécessite de l'information suivante afin de produire le contrat de traitement.",
    agreementNeeded:
      "Votre acceptation est nécessaire pour pouvoir continuer avec la prestation de service",
    representativeFullName: "Représentant légal (nom complet)",
    representativeDNI: "SIRET du représentant légal",
    companyAddress: "Adresse de l’entreprise",
    accept: "J’accepte",
    pdfDownload: "Télécharger PDF",
  },
  search: {
    areMoreResults: "En montrant seulement 10 résultats",
  },
  requirement_names: {
    "1193": "Désignation de ressource préventive",
    "1197": "Acte de constitution Service Prévention Propre",
    "1204": "Livre d'instruction",
    "1205": "Permis de conduire",
    "2430": "Formation spécifique en Sécurité routière",
    "2960": "Formation sur les risques chimiques sur le lieu de travail",
    "4481": "Informations selon RD396 / 2006 (exposition à l'amiante)",
    "9700": "Plan de Sécurité et de Santé (PSS)",
    "1198": "Certificat d'audit réglementaire SPP",
    "12900": "Désignation Coordonnateur de sécurité et de santé",
    "4590774":
      "Entrainement 2¬∫ (2ème) cycle. Installateur d'éléments de menuiserie et de mobilier",
    "12905": "Certificat de formation/compétence pour les travaux de soudure",
    "4590776": "Formation dans la mise en place de réseau de sécurité",
    "12909":
      "Informations sur les risques et les mesures identifiés dans le Plan de sécurité et de santé",
    "4482":
      "Plan de travail pour les opérations de retrait ou d'entretien d'éléments avec de l'amiante",
    "12910":
      "Formation sur les risques identifiés dans le Plan de sécurité et de santé",
    "3312270": "Inscription à la Sécurité sociale (TA2)",
    "4484": "Formation de technicien en prévention des risques professionnels",
    "4485": "Formation en tant que coordinateur de sécurité et de santé",
    "12903": "Brevet grutier",
    "12906": "Formation d'assemblage d'échafaudages",
    "12913": "Certificat de révision obligatoire de la part d'OCA",
    "12915": "Spécifications techniques des équipements ATEX",
    "15606": "Certificat d'agrément cabine de décontamination fibrociment",
    "1209": "Rapport des travailleurs inscrits (ITA)",
    "15594": "Formation Convention 2¬∫ (2ème) cycle. Ferraille",
    "4590777": "Formation à la manipulation de pompes à béton",
    "4483": "Inscription au RERA (Registre des entreprises à risque d'amiante)",
    "15607": "Procès-verbal de l'approbation du Plan de sécurité et de santé",
    "15608": "Ouverture du centre de travail",
    "15595": "Formation Convention 2¬∫ (2ème) cycle. Coffrage",
    "3453202": "Formation Convention Métallurgie. Travaux de forge.",
    "15609": "Livre d'incidents",
    "4349202": "Plan de sauvetage dans des espaces confinés",
    "15611": "Livre de sous-traitance",
    "15612": "Adhésion au plan de sécurité et de santé (PSS)",
    "15613": "Désignation de ressource préventive",
    "15614": "Autorisation Gestionnaires / Transporteurs de déchets",
    "15615": "Document d'acceptation des déchets",
    "15616":
      "Registre des entreprises qui installent / maintiennent des systèmes de protection incendie (RECI)",
    "15617": "Registre des établissements et des services biocides (ROESB)",
    "15618": "Registre des établissements et services de pesticides (ROESP)",
    "15619": "Procédure de travail spécifique",
    "15603": "Formation cadres intermédiaires 20h (Convention)",
    "15604":
      "Formation responsable du chantier et des procédures techniques 20h (Convention)",
    "1098": "Certificat d'aptitude médicale pour le poste de travail affecté",
    "15592":
      "Formation Convention 2¬∫ (2ème) cycle. Isolation et imperméabilisation",
    "15593":
      "Formation Convention 2¬∫ (2ème) cycle. Fondations spéciales, sondages et forages",
    "15597": "Formation Convention 2¬∫ (2ème) cycle. La peinture",
    "15598":
      "Formation Convention 2¬∫ (2ème) cycle. Démolition et réhabilitation",
    "15599":
      "Formation Convention 2¬∫ (2ème) cycle. Réseaux d'approvisionnement et d'assainissement, puits",
    "15600":
      "Formation Convention 2¬∫ (2ème) cycle. Opérateurs d'équipement manuel",
    "15601":
      "Formation Convention 2¬∫ (2ème) cycle. Assemblage d'éléments préfabriqués en béton",
    "15602":
      "Formation Convention 2¬∫ (2ème) cycle. Mise en place de matériaux de couverture (toitures, etc.)",
    "330800": "Autorisation de transport ADR",
    "330801": "Certificat d'agrément ADR du véhicule",
    "330802": "Certificat de contrôle technique périodique du véhicule ADR",
    "3453203":
      "Formation Convention Métallurgie. Opérateurs en soudage et oxycoupage.",
    "307830":
      "TA.2 / S - Demande d'inscription à la sécurité sociale des travailleurs salariés ou assimilés (EPA uniquement)",
    "3312271": "Formation 2¬∫ (2ème) Cycle. Les métiers",
    "12901": "Permis de conduire",
    "3453208":
      "Formation Convention Métallurgie. Opérateurs dans les activités de préassemblage, d'assemblage, de changement de format et d'assemblage en usines.",
    "3116640": "TC2 / RNT (Liste nominale des travailleurs)",
    "4349200":
      "Certificat ISO 14001: 2015 (Système de management environnemental)",
    "4349203": "Instructions d'entrée dans des espaces confinés",
    "4480": "Formation lors de travaux avec l'amiante/le fibrociment",
    "3453209":
      "Formation Convention Métallurgie. Travaux de menuiserie métallique.",
    "3453470":
      "Formation Convention Métallurgie. Les opérateurs de joaillerie-orfèvrerie.",
    "3453200":
      "Formation Convention Métallurgie. Fabrication, production et transformation du fer, l'acier et les métaux non-ferreux et première transformation.",
    "3453201":
      "Formation Convention Métallurgie. Travaux de construction, de réparation et de maintenance navale dans les chantiers navals et les quais.",
    "3453471":
      "Formation Convention Métallurgie. Travaux mécaniques, maintenance et réparation de machines, d'équipements industriels et / ou d'équipements électromécaniques.",
    "3453204":
      "Formation Convention Métallurgie. Opérateurs de machines d'usinage par enlèvement de copeaux.",
    "3453205":
      "Formation Convention Métallurgie. Opérateurs de machines d'usinage à l'abrasion.",
    "3453206":
      "Formation Convention Métallurgie. Opérateurs de machines d'usinage par déformation et coupe du métal.",
    "3453207":
      "Formation Convention Métallurgie. Opérateurs dans les activités de traitement de surface de pièces métalliques : dégraissage, nettoyage, décapage, revêtement, peinture.",
    "3453472":
      "Formation Convention Métallurgie. Travaux dans des ateliers de réparation de véhicules.",
    "3453473":
      "Formation Convention Métallurgie. Travaux d'installation, maintenance et réparation de matériel informatique, des automatismes et de leur programmation, des ordinateurs et de leurs périphériques ou auxiliaires, des équipements et  technologies de télécommunication et d'information, des réseaux d'information et de données (TIC)",
    "3453474":
      "Formation Convention Métallurgie. Installateurs et réparateurs de lignes et d'équipements électriques.",
    "3453475":
      "Formation Convention Métallurgie. Travaux de plomberie, installations de chauffage et de climatisation, installations de production d'eau chaude sanitaire et installations thermiques solaires.",
    "3453476":
      "Formation Convention Métallurgie. Travaux de maintenance, réparation et installation d'ascenseurs.",
    "3453477":
      "Formation Convention Métallurgie. Travaux d'isolation et d'imperméabilisation.",
    "3453478":
      "Formation Convention Métallurgie. Travaux de montage de structures tubulaires.",
    "12904": "Brevet d'application de pesticides / biocides",
    "3453479":
      "Formation Convention Métallurgie. Travaux de construction et maintenance des voies de chemin de fer.",
    "3453530":
      "Formation Convention Métallurgie. Travaux d'installation, maintenance et réparation d'infrastructures de télécommunications (TIC et numérisation).",
    "3453531":
      "Formation Convention Métallurgie. Travaux sur les gazoducs et les réseaux de distribution de gaz combustible.",
    "3453532":
      "Formation Convention Métallurgie. Travaux d'autres types d'installations telles que des installations solaires photovoltaïques ou des installations éoliennes.",
    "3453533":
      "Formation Convention Métallurgie. Récupération et recyclage de matières premières métalliques.",
    "3453534":
      "Formation Convention Métallurgie. Contrôle de la qualité, vérification et inspection des matériaux en cours de fabrication et sur les produits finis du secteur.",
    "12897": "Brevet d'électricien qualifié selon RD614 / 2001",
    "1202": "Assurance de responsabilité civile. Justificatif de paiement",
    "3480860": "CAP",
    "1211":
      "Formation Convention 2¬∫ (2ème) cycle. Opérateur de dispositifs élévateurs",
    "1203": "Déclaration de conformité (marquage CE) ou adaptation à RD1215",
    "4590772":
      "Formation Convention 2¬∫ (2ème) cycle. Stabilisation d'esplanades et pose de revêtement de chaussées",
    "3453535": "Formation Convention Métallurgie. Conducteurs / transporteurs",
    "3453536":
      "Formation Convention Métallurgie. Conducteurs de chariots élévateurs.",
    "3453537": "Formation Convention Métallurgie. Grutiers.",
    "3453538":
      "Formation Convention Métallurgie. Opérateurs de plateformes élévatrices.",
    "3453539":
      "Formation Convention Métallurgie. Exploitants d’entrepôt et de logistique et approvisionnement dans les processus de fabrication (y compris l’emballage, la préparation du produit, le retraitement, avec ou sans éléments mécaniques et autres tâches d’achat et fournitures de matériaux et de composants).",
    "3453590":
      "Formation Convention Métallurgie. Grutiers de machines autopropulsées.",
    "15590": "Formation à l'utilisation des engins de terrassement",
    "12907":
      "Formation aux traitements hygiéniques sanitaires dans les établissements à risque de légionellose",
    "12898": "Formation à l'utilisation de chariots élévateurs",
    "15596":
      "Formation Convention 2¬∫ (2ème) cycle. Plomberie et Installations de climatisation",
    "4349201": "Certificat d'examen des harnais",
    "4172690": "DURC. Document unique de régularité contributive",
    "12902": "Brevet ADR",
    "4590775": "Formation en prévention des risques professionnels",
    "4590771": "Carte sanitaire européenne",
    "4590773": "Brevet de manipulateurs d'aliments",
    "1201": "Assurance de responsabilité civile. Police",
    "12908": "Formation à l'utilisation d'autochargeuses",
    "1191": "Formation Convention 2¬∫ (2ème) cycle. Maçonnerie",
    "1206": "Inspection technique de véhicule (ITV ou ITGA)",
    "434350": "Informations sur les risques pouvant affecter des tiers",
    "4172691": "Certificat A1",
    "1192": "Formation Convention. Niveau basique 60h-50h",
    "1096": "Formation spécifique pro pour les travaux en hauteur",
    "310060": "Formation Convention 2¬∫ (2ème) cycle. Revêtements en plâtre.",
    "1208":
      "Certificat de Sécurité sociale (TGSS) d'être à jour avec le paiement",
    "12896": "Brevet électricien certifié selon RD614 / 2001",
    "1097": "Formation spécifique pro pour travaux dans des espaces confinés",
    "3550":
      "Formation Convention 2¬∫ (2ème) cycle. Installations, assemblages, structures métalliques",
    "12891":
      "Contrat d'assurance accidents Convention. Justificatif de paiement",
    "1093": "Document d'identité (DNI, Nie, Passport)",
    "12911":
      "Assurance responsabilité civile du véhicule. Justificatif de paiement.",
    "12893": "Document d'association à une Mutuelle",
    "12892": "Fiches de sécurité de substances chimiques",
    "12914": "Fiche technique",
    "1194":
      "Autorisation d'utilisation de machines-outils (équipement de travail)",
    "1207":
      "Certificat de l'Agence fiscale (AEAT) d'être à jour avec le paiement.",
    "1196": "Certificat de modalité préventive adopté dans l'entreprise",
    "12895": "TC2 / RNT (Liste nominale des travailleurs)",
    "12912":
      "Certificat de révision obligatoire de l'équipement de travail selon indications du fabricant",
    "12899": "Formation à l'utilisation de plateforme élévatrice (PEMP)",
    "15605":
      "RETA. Sécurité sociale de travailleur indépendant. Justificatif de paiement.",
    "1195":
      "Évaluation des risques des travaux à effectuer et mesures préventives",
    "12890": "Contrat d'assurance accident. Police",
    "15610":
      "Inscription au REL (Registre des entreprises accréditées du secteur du bâtiment)",
    "1199": "Contrat ou certificat du service de prévention externe",
    "4590770": "Assurance véhicule",
    "1190": "Formation Convention 2¬∫ (2ème) cycle. Électricité",
    "1095":
      "Formation à la prévention des risques professionnels liés à l'emploi (art. 19 LPRL)",
    "1094": "Mise à disposition d'équipement de protection individuelle (EPI)",
    "1200": "Justificatif d'être à jour avec le Service de prévention externe",
    "1099":
      "Informations des risques encourus sur le lieu de travail (art. 18 LPRL)",
    "1090": "ITA. Rapport des travailleurs inscrits",
    "15591":
      "Formation Convention 2¬∫ (2ème) cycle. Conducteurs de véhicules et d'engins de terrassement",
    "11070":
      "TC1 / RLC (reçu de règlement de paiement) + justificatif de paiement",
  },
};
